import React, { useState, useEffect } from "react";
import Footer from '../../../components/footer/footer';
import Header from '../../../components/header/header';
import ProductTable from "../productManagement/productTable";
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import MaterialTable from 'material-table'
import image_url from "../../../../config/BaseUrl"
import { Link, NavLink, useHistory } from "react-router-dom";
import DropdownButton from 'react-bootstrap/DropdownButton'
import Button from 'react-bootstrap/Button'
import { makeStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton";
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import { RemoveRedEye } from "@material-ui/icons"
import { Modal } from 'react-bootstrap';

import '../productManagement/productManagement.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));


export default function AffilateMarket() {

    const [afilatemarket, Setafilatemarket] = useState([])
    const [Loader, setLoader] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null);


    //ADD MODAL
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const classes = useStyles();

    //image states
    const [SelectedThumbnail, setSelectedThumbnail] = useState(null)
    const [UpdateThumbnail, setUpdateThumbnail] = useState(null)
    const [AddSubProfile, SetAddSubProfile] = useState(null)
    const [Selectedprofile, setSelectedprofile] = useState(null)
    const [Active, SetActive] = useState(true);


    //add states
    const [Productname, setProductname] = useState("")
    const [url, seturl] = useState("")
    const [price, SetPrice] = useState("")
    const [websiteName, SetwebsiteName] = useState("")

     //add validation states
     const [AddProductnameErr, SetAddProductnameErr] = useState({})
     const [AddurlErr, SetAddurlErr] = useState({})
     const [AddpriceErr, SetAddpriceErr] = useState({})
     const [AddwebsiteNameErr, SetAddwebsiteNameErr] = useState({})
     const [AddSubProfileErr, SetAddSubProfileErr] = useState({})



    //edit state
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // edit states

    const [editProductname, seteditProductname] = useState("")
    const [editurl, setediturl] = useState("")
    const[editprice, SeteditPrice] = useState("")
    const [editwebsiteName, SeteditwebsiteName] = useState("")
    const [profileimage, setprofileimage] = useState("")
    const [productid, setproductid] = useState("")


     //Edit validation states
     const [editProductnameErr, SeteditProductnameErr] = useState({})
     const [editurlErr, SetediturlErr] = useState({})
     const [editpriceErr, SeteditpriceErr] = useState({})
     const [editwebsiteNameErr, SeteditwebsiteNameErr] = useState({})
     const [profileimageErr, SetprofileimageErr] = useState({})





    const Token = localStorage.getItem('SellerToken');

    useEffect(() => {
        AffilateMarket("Pending")

    }, [])


    // PROFILE IMAG WORK


    const handleInputFileChange = (event, func) => {
        var file = event.target.files[0];
        var reader = new FileReader();
        // console.log(file);
        const filesize = file.size
        const filesizeround = Math.round((filesize / 1024))
        if (filesizeround > 1024) {

            Swal.fire({
                title: "Opps",
                text: "plz select your image less then and equals to 1mb",
                icon: "warning"
            });
        } else {
            var url = reader.readAsDataURL(file);
            // console.log(url)
            reader.onloadend = function (e) {
                setSelectedprofile(reader.result);
                setUpdateThumbnail(file);


                // console.log("data-======>",reader.result)
            };
            // console.log(selectProfileImage)
            func(event.target.files[0])
            // setdisable(false)
        }
    }

    //view markets


    const AffilateMarket = (status) => {
        if (status == "Pending") {
            SetActive(true)
        }

        if (status == "Active") {
            SetActive(false)
        }


        setLoader(true);

        getApiWithToken(`${BaseUrl.base_url}/affilated_Marketing?Status=${status}`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    Setafilatemarket(data.data)
                    console.log("Setafilatemarket PRODCT==>", data)
                    // setdisable(true);
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }


    // Add SubAdmin
    const AddMarketing = () => {

        const isValid = formValidation();
        if (isValid== true){

        setLoader(true);
        var formdata = new FormData();
        formdata.append("ProductName", Productname);
        formdata.append("url", url);
        formdata.append("Productimage", AddSubProfile);
        formdata.append("Price", price);
        formdata.append("WebsiteName", websiteName);



        PostApi(`${BaseUrl.base_url}/affilated_Marketing`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                console.log("AddDATA==>", data)
                if (data.status) {


                    setSelectedThumbnail(null)
                    SetAddSubProfile(null)
                    setSelectedprofile(null)
                    AffilateMarket("Pending")
                    handleClose2()

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
        }

            else{
                formValidation()


            }
    }



     //add validation
     const formValidation = () => {
        const AddProductnameErr = {};
        const AddurlErr = {};
        const AddpriceErr = {};
        const AddwebsiteNameErr = {};
        const AddSubProfileErr = {};




        let isValid = true;

        if (Productname.length == 0) {
            AddProductnameErr.firstNamenull = "Required Product Name";
            isValid = false;
        }

        if (AddSubProfile == null) {
            AddSubProfileErr.firstNamenull = "Required Image";
            isValid = false;
        }

        if (url.length == 0) {
            AddurlErr.firstNamenull = "Required Url";
            isValid = false;
        }

        if (price.length == 0) {
            AddpriceErr.firstNamenull = "Required Price";
            isValid = false;
        }

        if (websiteName.length == 0) {
            AddwebsiteNameErr.firstNamenull = "Required website Name";
            isValid = false;
        }


        SetAddProductnameErr(AddProductnameErr);
        SetAddurlErr(AddurlErr);
        SetAddpriceErr(AddpriceErr);
        SetAddwebsiteNameErr(AddwebsiteNameErr);
        SetAddSubProfileErr(AddSubProfileErr);



        return isValid;
    }


    //DELETE API

    const Delete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete it",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteProduct(id)
            }
        })

    }
    // DELETE API

    const DeleteProduct = (id) => {
        setLoader(true);
        var formdata = new FormData();
        formdata.append("id", id);
        DeleteApi(`${BaseUrl.base_url}/affilated_Marketing?id=${id}`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("dELETEproduct==>", data)
                    AffilateMarket("Pending")

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    }


    // Edit API

     const UpdateProducts = () => {
        const isValid = UpdateformValidation();
        if (isValid== true){


            setLoader(true);
            var formdata = new FormData();
            formdata.append("ProductName", editProductname);
            formdata.append("url", editurl);
            formdata.append("Price", editprice);
            formdata.append("WebsiteName", editwebsiteName);
            formdata.append("Productimage", UpdateThumbnail);
            formdata.append("id", productid);


            PutApi(`${BaseUrl.base_url}/affilated_Marketing`, formdata, Token)
                .then(({ data }) => {
                    setLoader(false);
                    if (data.status) {
                        console.log("dataaPut==>",data)
                        AffilateMarket("Pending")
                        handleClose()

                        Swal.fire({
                            title: "success",
                            text: data.message,
                            icon: "success"
                        })
                    } else {
                        // $(".loader").hide();
                        Swal.fire({
                            title: "Opps",
                            text: data.message,
                            icon: "error"
                        })
                    }
                }).catch(function (e) {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: e.message,
                        icon: "error"
                    })
                })
            }
            else{
                UpdateformValidation()


            }

    }




        //updateapi validation

    const UpdateformValidation = () => {
        const editProductnameErr = {};
        const editurlErr = {};
        const editpriceErr = {};
        const editwebsiteNameErr = {};
        const profileimageErr = {};





        let isValid = true;

        if (editProductname == "") {
            editProductnameErr.firstamenull = "Required Product Name";
            isValid = false;
        }

        if (profileimage == null) {
            profileimageErr.firstNmenull = "Required Image";
            isValid = false;
        }

        if (editurl == "") {
            editurlErr.firstNaenull = "Required Url";
            isValid = false;
        }

        if (editprice == "") {
            editpriceErr.firstNamnull = "Required Price";
            isValid = false;
        }

        if (editwebsiteName == "") {
            editwebsiteNameErr.firstNameull = "Required website Name";
            isValid = false;
        }



     SeteditProductnameErr(editProductnameErr);
     SetediturlErr(editurlErr);
     SeteditpriceErr(editpriceErr);
     SeteditwebsiteNameErr(editwebsiteNameErr);
     SetprofileimageErr(profileimageErr);

        return isValid;
    }






    return (
        <>
            {Loader ? <div className="loader" /> : null}
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <Header />
                    <div className="main-content">
                        <section className="section">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h4>Orders Overview</h4>
                                        </div>
                                        <div className="card-body">
                                            <section className="section">
                                                <div className="section-body">
                                                    <div className="row clearfix">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <div className="card">

                                                                <div className="card-body" style={{ paddingTop: 0 }}>
                                                                    <div className="card-body">
                                                                        <button onClick={() => AffilateMarket("Pending")} type="button" class={Active ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"}> Pending</button>
                                                                        <button onClick={() => AffilateMarket("Active")} type="button" class="expTopbar btn btn-outline-primary "  >Active</button>

                                                                        <div class="col-md-12 text-right">
                                                                            <button onClick={() => handleShow2()} type="button" class="btn btn-primary  " style={{ marginTop: "-24px" }}>Add</button>

                                                                        </div>




                                                                    </div>


                                                                    <div className="card-body" style={{ padding: 5 }}>
                                                                        <div className="row" style={{ marginLeft: 10 }}>

                                                                        </div>



                                                                        <div className="card-body">
                                                                            <MaterialTable
                                                                                data={afilatemarket}
                                                                                title=""
                                                                                columns={[
                                                                                    { title: 'Product_Name', field: 'ProductName' },
                                                                                    { title: 'Product_Image', field: 'Productimage', render: item => <img src={(item.Productimage)} alt="" border="3" height="80" width="80" /> },
                                                                                    { title: 'Price', field: 'Price' },
                                                                                    { title: 'Website_Name', field: 'WebsiteName' },
                                                                                    { title: 'Product_URL', field: 'url' },







                                                                                ]}

                                                                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                                                                options={{
                                                                                    rowStyle: rowData => ({
                                                                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                                                    }),
                                                                                    actionsColumnIndex: -1,
                                                                                }}


                                                                                actions={[
                                                                                    rowData => ({
                                                                                        icon: 'edit',
                                                                                        tooltip: 'Edit User',
                                                                                        //   onClick: (event, rowData) => history.push("/EditSubadmin")
                                                                                        // onClick: () => history.push("/EditSubadmin")
                                                                                        onClick: () => {
                                                                                            handleShow()
                                                                                          console.log("rowdataedit ka ",rowData)
                                                                                          seteditProductname(rowData.ProductName)
                                                                                          setediturl(rowData.url)
                                                                                          SeteditwebsiteName(rowData.WebsiteName)
                                                                                          SeteditPrice(rowData.Price)
                                                                                          setprofileimage(rowData.Productimage)
                                                                                          setproductid(rowData.id)




                                                                                        }

                                                                                    }),

                                                                                    rowData => ({
                                                                                        icon: 'delete',
                                                                                        tooltip: 'Delete User',
                                                                                        onClick: () => {
                                                                                            console.log("rowDataproduct==>", rowData)

                                                                                            Delete(rowData.id)
                                                                                            // setDelSubEmail()
                                                                                        }


                                                                                    })
                                                                                ]}





                                                                                options={{
                                                                                    // maxBodyHeight: 400,
                                                                                    // overflowX: 'x-scroll',
                                                                                    actionsColumnIndex: 9,
                                                                                    headerStyle: {
                                                                                        backgroundColor: '#222',
                                                                                        color: "white"
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>

            {/*  modal for Add  */}



            <Modal className="btn-close"
                size="md"
                aria-labelledby="example-modal-sizes-title-md"
                show={show2}
                onHide={handleClose2}>
                <Modal.Header >
                    <Modal.Title>Add Products</Modal.Title>
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>

                    <form>
                        <div className="form-row text-center " style={{ display: "block" }}>


                            <div className="row">
                                <div className="col-md-12 ">
                                    <center>
                                        <div className="upload_photo_main">
                                            <input onChange={(e) => handleInputFileChange(e, SetAddSubProfile)}
                                                accept="image/*"
                                                className={classes.input}
                                                id="icon-button-file"
                                                type="file"

                                            />
                                            <label htmlFor="icon-button-file">

                                                <IconButton
                                                    color="#414141"
                                                    aria-label="upload picture"
                                                    component="span"
                                                    className="upload_photo_main"
                                                >
                                                    <img style={{ minWidth: "120px", maxWidth: "120px", borderRadius: "20px" }}
                                                        src={Selectedprofile ? Selectedprofile : 'assets/img/cam.jpg'}

                                                        className="upload_photo_main" />
                                                </IconButton>
                                                <div>
                                                    {Object.keys(AddSubProfileErr).map((key) => {
                                                        return <div style={{ color: "red" }}>{AddSubProfileErr[key]}</div>
                                                    })}
                                                </div>
                                            </label>
                                        </div>
                                    </center>
                                </div>
                            </div>


                        </div>
                        <div className="form-row ">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputFN">Product Name</label>
                                <input type="text" onChange={(e) => setProductname(e.target.value)} className="form-control" id="inputPN" placeholder="Enter Your Product Name" />
                                <div>
                                    {Object.keys(AddProductnameErr).map((key) => {
                                        return <div style={{ color: "red" }}>{AddProductnameErr[key]}</div>
                                    })}
                                </div>
                            </div>
                        </div>


                        <div className="row " >


                            <div className="form-group col-md-12">
                                <label htmlFor="inputLN">Product Price</label>
                                <input type="text"  onChange={(e) => SetPrice(e.target.value)} className="form-control" id="inputPP" placeholder="Enter Product Price" />
                                <div>
                                    {Object.keys(AddpriceErr).map((key) => {
                                        return <div style={{ color: "red" }}>{AddpriceErr[key]}</div>
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="row " >


                            <div className="form-group col-md-12">
                                <label htmlFor="inputLN">Website Name</label>
                                <input  type="text" onChange={(e) => SetwebsiteName(e.target.value)} className="form-control" id="inputWN" placeholder="Enter Your Website Name" />
                                <div>
                                        {Object.keys(AddwebsiteNameErr).map((key) => {
                                            return <div style={{ color: "red" }}>{AddwebsiteNameErr[key]}</div>
                                        })}
                                    </div>
                            </div>
                        </div>

                        <div className="row " >


                            <div className="form-group col-md-12">
                                <label htmlFor="inputLN">Product URL</label>
                                <input type="text" onChange={(e) => seturl(e.target.value)} className="form-control" id="inputWU" placeholder="Enter Your Product URL" />
                                <div>
                                {Object.keys(AddurlErr).map((key) => {
                                return <div style={{ color: "red" }}>{AddurlErr[key]}</div>
                                })}
                                </div>
                            </div>
                        </div>



                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => AddMarketing()} >
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* MODAL FOR EDIT  */}



            <Modal className="btn-close"
                size="md"
                aria-labelledby="example-modal-sizes-title-md"
                show={show}
                onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Edit Product</Modal.Title>
                    <i onClick={handleClose} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>

                    <form>
                        <div className="form-row text-center " style={{ display: "block" }}>


                            <div className="row">
                                <div className="col-md-12 ">
                                    <center>
                                        <div className="upload_photo_main">
                                            <input onChange={(e) => handleInputFileChange(e, setprofileimage)}
                                                accept="image/*"
                                                className={classes.input}
                                                id="icon-button-file"
                                                type="file"

                                            />
                                            <label htmlFor="icon-button-file">

                                                <IconButton
                                                    color="#414141"
                                                    aria-label="upload picture"
                                                    component="span"
                                                    className="upload_photo_main"
                                                >
                                                    <img style={{ minWidth: "120px", maxWidth: "120px", borderRadius: "20px" }}
                                                        src={Selectedprofile ? Selectedprofile : (`${(profileimage)}` !== "https://backend.fridaymart.mlnull" ? `${(profileimage)}` : 'assets/img/cam.jpg')}

                                                        className="upload_photo_main" />
                                                </IconButton>
                                                <div>
                                                    {Object.keys(profileimageErr).map((key) => {
                                                        return <div style={{ color: "red" }}>{profileimageErr[key]}</div>
                                                    })}
                                                </div>
                                            </label>
                                        </div>
                                    </center>
                                </div>
                            </div>


                        </div>
                        <div className="form-row ">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputFN">Product Name</label>
                                <input value={editProductname} onChange={(e) => seteditProductname(e.target.value)}   type="text"  className="form-control" id="inputPN" placeholder="Enter Your Product Name" />
                                <div>
                                    {Object.keys(editProductnameErr).map((key) => {
                                        return <div style={{ color: "red" }}>{editProductnameErr[key]}</div>
                                    })}
                                </div>
                            </div>
                        </div>


                        <div className="row " >


                            <div className="form-group col-md-12">
                                <label htmlFor="inputLN">Product Price</label>
                                <input value={editprice}  onChange={(e) => SeteditPrice(e.target.value)} type="text"  className="form-control" id="inputPP" placeholder="Enter Product Price" />
                                <div>
                                    {Object.keys(editpriceErr).map((key) => {
                                        return <div style={{ color: "red" }}>{editpriceErr[key]}</div>
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="row " >


                            <div className="form-group col-md-12">
                                <label htmlFor="inputLN">Website Name</label>
                                <input value={editwebsiteName} onChange={(e) => SeteditwebsiteName(e.target.value)} type="text" className="form-control" id="inputWN" placeholder="Enter Your Website Name" />
                                <div>
                                {Object.keys(editwebsiteNameErr).map((key) => {
                                    return <div style={{ color: "red" }}>{editwebsiteNameErr[key]}</div>
                                })}
                            </div>
                            </div>
                        </div>

                        <div className="row" >


                            <div className="form-group col-md-12">
                                <label htmlFor="inputLN">Product URL</label>
                                <input value={editurl} onChange={(e) => setediturl(e.target.value)} type="text"   className="form-control" id="inputWU" placeholder="Enter Your Product URL" />
                            <div>
                                        {Object.keys(editurlErr).map((key) => {
                                        return <div style={{ color: "red" }}>{editurlErr[key]}</div>
                                        })}
                                        </div>
                            </div>
                        </div>



                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={()=>UpdateProducts()}  >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}