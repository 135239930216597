import React, { useState, useEffect } from "react"
import AdminHeader from "../components/adminheader/AdminHeader"
import AdminFooter from "../components/adminfooter/AdminFooter"
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from 'material-table'
import { RemoveRedEye } from "@material-ui/icons"
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import IconButton from "@material-ui/core/IconButton"
import image_url from "../../../../config/BaseUrl"

import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";

import BootstrapSwitchButton from 'bootstrap-switch-button-react'





const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));

export default function PendingAffilate() {
    const [selectedRow, setSelectedRow] = useState(null);


    const [GetAllPending, SetGetAllPending] = useState([])

    const [Loader, setLoader] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [SellerAccount, SetSellerAccount] = useState("")


     // Second Modal
     const [show2, setShow2] = useState(false);
     const handleClose2 = () => setShow2(false);
     const handleShow2 = () => setShow2(true);


    // console.log("SellerAccount",SellerAccount)


      // VIEW AllBrandsName API
      const Token = localStorage.getItem('Token');
      useEffect(() => {
        ViewPendingAffilate("Pending")
      }, [])


    const ViewPendingAffilate = (status) => {
        setLoader(true);

        getApiWithToken(`${BaseUrl.Admin_Url}/affilated_Marketing?Status=${status}`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("pendingdata",data)
                    SetGetAllPending(data.data)


                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }


       // UPDATE STATUS PUT API

       const UpdateStatus = (Productid) => {
        setLoader(true);
        var formdata = new FormData();

        formdata.append("id", Productid);
        formdata.append("Status","Active");
        // formdata.append("Percentage",EditPercentage);

        PutApi(`${BaseUrl.Admin_Url}/affilated_Marketing`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("inactive==>", data)
                    ViewPendingAffilate("Pending")

                    // handleClose2()
                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    }


    return (
        <>
    {Loader?<div class="loader"></div>:null}
            <div id="app">
                <AdminHeader />
                <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0"> Pending</h4>
                                </li>

                                <li className="breadcrumb-item active"> Affilate Products </li>


                                {/* <div class="row">
                                    <div class="col-md-12  text-right">
                                    <button   type="button" class="btn btn-primary">Add Category</button>

                                    </div>
                                </div> */}
                            </ul>


                            <div style={{ maxWidth: '100%' }}>
                                <MaterialTable
                                 data={GetAllPending}

                                 title=""
                                 columns={[

                                        {
                                            title: 'Product_Name', field:"ProductName"
                                        },

                                        { title: 'Product_Image', field: 'Productimage' , render: item => <img src={(item.Productimage)} alt="" border="3" height="80" width="80" /> },


                                        { title: "Price", field: "Price" },
                                        { title: "Url", field: "url" },


                                        {


                                            title: 'Switch',
                                            render: rowData => <BootstrapSwitchButton onChange={()=>UpdateStatus(rowData.id)}   checked={false} size="md" />
                                          }



                                        // { title: "Percentage ", field: "Percentage", },

                                        //  <Button >Button</Button>



                                    ]}


                                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                    options={{
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        }),
                                        actionsColumnIndex: -1,
                                    }}
                                    // other props
                                    // actions={[
                                    //     rowData => ({
                                    //         icon: RemoveRedEye,
                                    //         tooltip: 'View Seller Detail',
                                    //         isFreeAction: true,
                                    //         onClick: (event, rowData) => {
                                    //             // console.log(rowData.Sellerid, "rowdatasell")
                                    //             SetSellerAccount(rowData)
                                    //             console.log("right",rowData.sellerid)
                                    //             handleShow()

                                    //         }


                                    //     }),





                                    // ]}
                                    options={{
                                        maxBodyHeight: 400,
                                        overflowX: 'x-scroll',
                                        actionsColumnIndex: 7,
                                        headerStyle: {
                                            backgroundColor: '#222',
                                            color: "white"
                                          }
                                      }}
                                />


                            </div>


                        </section>

                    </div>
                    <AdminFooter />
                </div>
            </div>









        </>
    )
}
