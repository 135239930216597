import React, { useState, useEffect } from "react"
import Footer from '../../../components/footer/footer'
import Header from '../../../components/header/header'
import './addProduct.css'

export default function AddProduct() {
  const [Loader, setLoader] = useState(false)
  return (
    <>
     {/* {Loader? <div className="loader" />:null} */}
      <div id="app">
        <div className="main-wrapper main-wrapper-1">
          <Header />
          <div className="main-content">
            <section className="section">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h4>Add product</h4>
                    </div>
                    <div className="card-body">
                      <section className="section">
                        <div className="section-body">
                          <div className="row clearfix">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="card">
                                {/* <div className="card-header">
                                                                <h4>Vertical Layout</h4>
                                                            </div> */}
                                <div className="card-body">
                                  <form id="wizard_with_validation" method="POST">
                                    <h3 className="wizardTitle">Vital Info</h3>
                                    <fieldset>
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <div className="alert alert-secondary" role="alert">
                                            <div className="row">
                                              <div className="col-lg-1">
                                                <i style={{ fontSize: "55px" }} className="fas fa-exclamation-circle"></i>
                                              </div>
                                              <div className="col-md-11">
                                                <p className="mb-0" style={{ color: 'rgb(82, 80, 80)' }}>When multiple seller sells the same product through a single detail page we combine and present the best product data to ensure costumers get the best experience.</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Product ID</b></label>
                                              <input placeholder="8956775556376" type="text" className="form-control" required />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label">ID Categorey</label>
                                              <select className="form-control">
                                                <option>UPC</option>
                                                <option>ATP</option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Title</b></label>
                                              <input placeholder="Acme 7KMKN - 50'" type="text" className="form-control" required />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Brand</b></label>
                                              <input placeholder="Acme" type="text" className="form-control" required />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Manufacturer</b></label>
                                              <input placeholder="Acme International" type="text" className="form-control" required />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="form-group form-float">
                                            <div className="form-group form-float">
                                              <div className="form-line">
                                                <label className="form-label"><b>Mfr Part Number</b></label>
                                                <input placeholder="SB-122" type="number" className="form-control" required />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </fieldset>
                                    <h3 className="wizardTitle">Variations</h3>
                                    <fieldset>
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <div className="alert alert-secondary" role="alert">
                                            <div className="row">
                                              <div className="col-lg-1">
                                                <i style={{ fontSize: "55px" }} className="fas fa-exclamation-circle"></i>
                                              </div>
                                              <div className="col-md-11">
                                                <p className="mb-0" style={{ color: 'rgb(82, 80, 80)' }}>When multiple seller sells the same product through a single detail page we combine and present the best product data to ensure costumers get the best experience.</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label">Variations Theme</label>
                                              <select className="form-control">
                                                <option>ColorName</option>
                                                <option>SizeName</option>
                                                <option>ColorName-SizeName</option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </fieldset>
                                    <h3 className="wizardTitle">Offers</h3>
                                    <fieldset>
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <div className="alert alert-secondary" role="alert">
                                            <div className="row">
                                              <div className="col-lg-1">
                                                <i style={{ fontSize: "55px" }} className="fas fa-exclamation-circle"></i>
                                              </div>
                                              <div className="col-md-11">
                                                <p className="mb-0" style={{ color: 'rgb(82, 80, 80)' }}>You have the option to save as in active now and add its offer back in later.</p> <br />
                                                <div className="form-check">
                                                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                  <label className="form-check-label" for="flexCheckDefault">
                                                    Let me skip the offer data and add it later
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Seller SKU</b></label>
                                              <input placeholder="i12-white" type="text" className="form-control" required />
                                              <div className="col-md-5">Once submitted this field can't be changed</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-3">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Your Price</b></label>
                                              <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-addon1">$</span>
                                                <input type="number" className="form-control" placeholder="20.00" aria-label="Username" aria-describedby="basic-addon1" required />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Quantity</b></label>
                                              <input placeholder="" type="text" className="form-control" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Conditions</b></label>
                                              <select className="form-control">
                                                <option>New</option>
                                                <option>Used-Acceptable</option>
                                                <option>Used-Good</option>
                                                <option>Used-Like New</option>
                                                <option>Used-Very Good</option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>


                                      <div className="row">
                                        <div className="col-md-12 ml-4">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Fulfillment Channel</b></label> <br />
                                              <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                                <label className="form-check-label" for="inlineRadio1">I will ship this item myself (Merchant Fulfilled)</label>
                                              </div> <br />
                                              <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                                <label className="form-check-label" for="inlineRadio2">Babylon will ship and provide costumer service (Fulfilled by Babylon)</label>
                                              </div> <br /><br />
                                              <div className="col-md-5">You have chosen to have Babylon fulfill orders for this item, you item will be avaiable for sell, when babylon recevies it. Fulfillment by Babylon fees will apply.</div>
                                              <div className="collapse" style={{ width: "98%" }} id="collapseExample">
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <input placeholder="Address Line 1" type="text" className="form-control" required />
                                                  </div>
                                                  <div className="col-md-6">
                                                    <input placeholder="Address Line 2" type="text" className="form-control" required />
                                                  </div>
                                                </div>
                                                <div className="row mt-2">
                                                  <div className="col-md-6">
                                                  </div>
                                                  <div className="col-md-6">
                                                    <input placeholder="Street No" type="text" className="form-control" required />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </fieldset>
                                    <h3 className="wizardTitle">Images</h3>
                                    <fieldset>
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <div className="alert alert-secondary" role="alert">
                                                <div className="row">
                                                  <div className="col-lg-1">
                                                    <i style={{ fontSize: "55px" }} className="fas fa-exclamation-circle"></i>
                                                  </div>
                                                  <div className="col-md-11">
                                                    <p className="mb-0" style={{ color: 'rgb(82, 80, 80)' }}>When multiple seller sells the same product through a single detail page we combine and present the best product data to ensure costumers get the best experience.</p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <p className="">Your Image Recommendations.</p>
                                          <p className="text-muted">Upload your recommendations fro product images</p>
                                          <p className="">See Image Guidlines</p>
                                          <div className="row">
                                            <div className="mb-3 col-md-3">
                                              <label for="formFileMultiple" className="form-label">Image-1</label>
                                              <input className="form-control" type="file" id="formFileMultiple" required />
                                            </div>
                                            <div className="mb-3 col-md-3">
                                              <label for="formFileMultiple" className="form-label">Image-2</label>
                                              <input className="form-control" type="file" id="formFileMultiple" />
                                            </div>
                                            <div className="mb-3 col-md-3">
                                              <label for="formFileMultiple" className="form-label">Image-3</label>
                                              <input className="form-control" type="file" id="formFileMultiple" />
                                            </div>
                                            <div className="mb-3 col-md-3">
                                              <label for="formFileMultiple" className="form-label">Image-4</label>
                                              <input className="form-control" type="file" id="formFileMultiple" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </fieldset>
                                    <h3 className="wizardTitle">Description</h3>
                                    <fieldset>
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <div className="alert alert-secondary" role="alert">
                                            <div className="row">
                                              <div className="col-lg-1">
                                                <i style={{ fontSize: "55px" }} className="fas fa-exclamation-circle"></i>
                                              </div>
                                              <div className="col-md-11">
                                                <p className="mb-0" style={{ color: 'rgb(82, 80, 80)' }}>When multiple seller sells the same product through a single detail page we combine and present the best product data to ensure costumers get the best experience.</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Description</b></label>
                                              <textarea placeholder="For speed and versitility LG 43 inches 4K LED TV(43UP7500PTZ), LG C1 77-inch 4K OLED TV and LG G1 77-inches 4K OLED TV are the latest TVs to buy in India.The company was set up to manufacture consumer electronics and home appliances for the local market after the Korean War." type="text" className="form-control" required />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Bullet Point</b></label>
                                              <input placeholder="3D glasses included" type="text" className="form-control" required />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Legal Disclaimer</b></label>
                                              <textarea placeholder="Must be atleast 18 and over to purchacse" type="text" className="form-control" required />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Babylon Warning</b></label>
                                              <select className="form-control">
                                                <option>High Alert</option>
                                                <option>No Warning Applicable</option>
                                                <option>Moderate</option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Babylon Warning Description</b></label>
                                              <input placeholder="No warning applicable" type="text" className="form-control" required />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </fieldset>
                                    <h3 className="wizardTitle">Keywords</h3>
                                    <fieldset>
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <div className="alert alert-secondary" role="alert">
                                            <div className="row">
                                              <div className="col-lg-1">
                                                <i style={{ fontSize: "55px" }} className="fas fa-exclamation-circle"></i>
                                              </div>
                                              <div className="col-md-11">
                                                <p className="mb-0" style={{ color: 'rgb(82, 80, 80)' }}>When multiple seller sells the same product through a single detail page we combine and present the best product data to ensure costumers get the best experience.</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Search Terms </b></label>
                                              <input placeholder="Electric" type="text" className="form-control" required />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <label className="form-label"><b>Platinum Keywords</b></label>
                                              <input placeholder="Platinum" type="text" className="form-control" required />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </fieldset>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                  <div className="mt-5 text-muted text-center">
                    Don't have an account? <a href="auth-register.html">Create One</a>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}