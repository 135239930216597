import React, { useState, useEffect } from "react"
import AdminHeader from "../components/adminheader/AdminHeader"
import AdminFooter from "../components/adminfooter/AdminFooter"
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from 'material-table'
import { useHistory } from "react-router";
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import image_url from "../../../../config/BaseUrl"
// import default_image from "../../../../../"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import IconButton from "@material-ui/core/IconButton";
import { mapToStyles } from "@popperjs/core/lib/modifiers/computeStyles";
// import{ MaterialTable} from "@material-ui/core/MaterialTable";
const background={
    color: "white",
    backgroundColor:"rgba(0,0,0,.65)"
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));

export default function SubadminView() {
    const [Loader, setLoader] = useState(false)
    const history = useHistory();
    const classes = useStyles();
    const [Disable, setDisable] = useState(true);


    // First Modal
    const [selectedRow, setSelectedRow] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // Second Modal
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    // Getdata API STATES
    const [Data, setData] = useState([]);
    // GET REPORT VALUE STATE
    const [AllRole, SetAllRole] = useState([])

    const [Email, SetEmail] = useState("")
    const [Contact, SetContact] = useState("")
    const [Fname, SetFname] = useState("")
    const [Lname, SetLname] = useState("")
    const [Role, SetRole] = useState("")
    const [profileimage, setprofileimage] = useState("")
    const [password, Setpassword] = useState("nochange")

    const [EfirstNameErr, setEFirstnameErr] = useState({});
    const [ElastNameErr, setELastnameErr] = useState({});
    const [EEmailErr, setEEmailErr] = useState({});
    const [ENumberErr, setENumberErr] = useState({});
    const [EPasswordErr, setEPasswordErr] = useState({});
    const [EAddRoleErr, setEAddRoleErr] = useState({});
    const [EAddImageErr, setEAddImageErr] = useState({});
    // ADDSUBADMIN STATES
    const [AddFName, SetAddFName] = useState("")
    const [AddLName, SetAddLName] = useState("")
    const [AddEmail, SetAddEmail] = useState("")
    const [AddContact, SetAddContact] = useState("")
    // const [AddProfile, SetAddProfile] = useState("")
    const [AddRole, SetAddRole] = useState("")
    const [AddPassword, SetAddPassword] = useState("")

    const [firstNameErr, setFirstnameErr] = useState({});
    const [lastNameErr, setLastnameErr] = useState({});
    const [EmailErr, setEmailErr] = useState({});
    const [NumberErr, setNumberErr] = useState({});
    const [PasswordErr, setPasswordErr] = useState({});
    const [AddRoleErr, setAddRoleErr] = useState({});
    const [AddImageErr, setAddImageErr] = useState({});




    const [SelectedThumbnail, setSelectedThumbnail] = useState(null)
    const [UpdateThumbnail, setUpdateThumbnail] = useState(null)
    const [AddSubProfile, SetAddSubProfile] = useState(null)
    const [Selectedprofile, setSelectedprofile] = useState(null)



    // TAKEN TOKEN FROM LOCALSTORAGE
    const Token = localStorage.getItem('Token');

    useEffect(() => {
        getData();
        getRole()
    }, [])

    // SHOW IMAG PIC





    // SHOW DATA IN MATERIAL TABLE CALL GET API
    const getData = () => {

        setLoader(true);
        getApiWithToken(`${BaseUrl.Admin_Url}/Add_SubAdmin`, "", Token)

            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    setData(data.data)
                    // console.log("dataa==>", data)
                    // setdisable(true);
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }

    // SELECT ROLE FROM ADMIN GETAPI
    const getRole = () => {

        getApiWithToken(`${BaseUrl.Admin_Url}/Add_role`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                // console.log("dataarole==>", data)
                if (data.status) {
                    SetAllRole(data.data)
                    // setdisable(true);
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }


    // EDITAPI EDIT SUBADMIN PANEL
    const UpdateSubadmin = () => {
        const isValid = UpdateformValidation();
        if (isValid == true) {
        console.log("aaaaa")

            setLoader(true);
            var formdata = new FormData();
            formdata.append("email", Email);
            formdata.append("password", password);
            formdata.append("fname", Fname);
            formdata.append("lname", Lname);
            formdata.append("contactNo", Contact);
            formdata.append("role", Role);
            formdata.append("profile", Selectedprofile);


            PutApi(`${BaseUrl.Admin_Url}/Add_SubAdmin`, formdata, Token)
                .then(({ data }) => {
                    setLoader(false);
                    if (data.status) {
                        console.log("dataaPut==>",data)
                        getData()
                        handleClose()

                        Swal.fire({
                            title: "success",
                            text: data.message,
                            icon: "success"
                        })
                    } else {
                        // $(".loader").hide();
                        Swal.fire({
                            title: "Opps",
                            text: data.message,
                            icon: "error"
                        })
                    }
                }).catch(function (e) {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: e.message,
                        icon: "error"
                    })
                })
        }
         else {
            UpdateformValidation()

        }
    }



    const UpdateformValidation = () => {
        const EfirstNameErr = {};
        const ElastNameErr = {};
        const EEmailErr = {};
        const ENumberErr = {};
        // const EPasswordErr = {};
        const EAddRoleErr = {}
        const EAddImageErr = {}

        const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');

        let isValid = true;

        if (Fname == 0) {
            EfirstNameErr.firstNamenull = "Required FirstName";
            isValid = false;
        }
        if (Lname == 0) {
            ElastNameErr.lastNamenull = "Required LastName";
            isValid = false;
        }
        if (Email == 0) {
            EEmailErr.emailnull = "Required Email";
            isValid = false;
        }

        if (Contact == 0) {
            ENumberErr.numbernull = "Required ContactNO";
            isValid = false;
        }


        if (Role == "") {
            EAddRoleErr.rollnull = "Required Role";
            isValid = false;
        }


        // if (password.length == 0) {
        //     EPasswordErr.passwordnull = "Required Password";
        //     isValid = false;
        // }else if (!validPassword.test(AddPassword)) {
        //     PasswordErr.passwordNll = "Enter Strong Password";
        //     isValid = false;
        // }



        if (profileimage == null) {
            EAddImageErr.passwordnull = "Required Image";
            isValid = false;
        }



        setEFirstnameErr(EfirstNameErr);
        setEEmailErr(EEmailErr);
        setELastnameErr(ElastNameErr);
        setENumberErr(ENumberErr)
        setEAddRoleErr(EAddRoleErr)
        // setEPasswordErr(EPasswordErr)
        setEAddImageErr(EAddImageErr)



        return isValid;
    }





    const Delete = (email) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete it",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteSubAdmin(email)
            }
        })

    }
    // DELETE API

    const DeleteSubAdmin = (email) => {
        setLoader(true);
        var formdata = new FormData();
        formdata.append("email", email);
        DeleteApi(`${BaseUrl.Admin_Url}/Add_SubAdmin`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("dELETE==>", data)
                    getData()

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    }


    // PROFILE IMAG WORK


    const handleInputFileChange = (event, func) => {
        var file = event.target.files[0];
        var reader = new FileReader();
        // console.log(file);
        const filesize = file.size
        const filesizeround = Math.round((filesize / 1024))
        if (filesizeround > 1024) {
            // setupdateProfileImage(null)
            // setselectProfileImage(null)
            Swal.fire({
                title: "Opps",
                text: "plz select your image less then and equals to 1mb",
                icon: "warning"
            });
        } else {
            var url = reader.readAsDataURL(file);
            // console.log(url)
            reader.onloadend = function (e) {
                setSelectedprofile(reader.result);
                setUpdateThumbnail(file);


                // console.log("data-======>",reader.result)
            };
            // console.log(selectProfileImage)
            func(event.target.files[0])
            // setdisable(false)
        }
    }

    // Add SubAdmin
    const AddSubmin = () => {
        const isValid = formValidation();
        if (isValid == true) {

            setLoader(true);
            var formdata = new FormData();
            formdata.append("email", AddEmail);
            formdata.append("password", AddPassword);
            formdata.append("fname", AddFName);
            formdata.append("lname", AddLName);
            formdata.append("contactNo", AddContact);
            formdata.append("role", AddRole);
            formdata.append("profile", AddSubProfile);




            PostApi(`${BaseUrl.Admin_Url}/Add_SubAdmin`, formdata, Token)
                .then(({ data }) => {
                    setLoader(false);
                    console.log("AddDATA==>", data)
                    if (data.status) {

                        getData()
                        SetAddFName("")
                        SetAddLName("")
                        SetAddPassword("")
                        SetAddContact("")
                        SetAddRole("")
                        SetAddEmail("")
                        setSelectedThumbnail(null)
                        SetAddSubProfile(null)
                        setSelectedprofile(null)
                        handleClose2()

                        Swal.fire({
                            title: "success",
                            text: data.message,
                            icon: "success"
                        })
                    } else {
                        // $(".loader").hide();
                        Swal.fire({
                            title: "Opps",
                            text: data.message,
                            icon: "error"
                        })
                    }
                }).catch(function (e) {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: e.message,
                        icon: "error"
                    })
                })


        } else {
            formValidation()
        }

    }


    const formValidation = () => {
        const firstNameErr = {};
        const lastNameErr = {};
        const EmailErr = {};
        const numberErr = {};
        const PasswordErr = {};
        const AddRoleErr = {}
        const AddImageErr = {}

        const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');

        let isValid = true;

        if (AddFName.length == 0) {
            firstNameErr.firstNamenull = "Required First Name";
            isValid = false;
        }
        if (AddLName.length == 0) {
            lastNameErr.lastNamenull = "Required Last Name";
            isValid = false;
        }
        if (AddEmail.length == 0) {
            EmailErr.emailnull = "Required Email";
            isValid = false;
        }

        if (AddContact.length == 0) {
            numberErr.numbernull = "Required Contact Number";
            isValid = false;
        }


        if (AddRole.length == 0) {
            AddRoleErr.rollnull = "Required Role";
            isValid = false;
        }


        if (AddPassword.length == 0) {
            PasswordErr.passwordnull = "Required Password";
            isValid = false;
        }else if (!validPassword.test(AddPassword)) {
            PasswordErr.passwordNll = "Enter Strong Password";
            isValid = false;
        }

        if (AddSubProfile == null) {
            AddImageErr.passwordnull = "Required Image";
            isValid = false;
        }

        setFirstnameErr(firstNameErr);
        setEmailErr(EmailErr);
        setLastnameErr(lastNameErr);
        setNumberErr(numberErr)
        setAddRoleErr(AddRoleErr)
        setPasswordErr(PasswordErr)
        setAddImageErr(AddImageErr)



        return isValid;
    }


    return (
        <>
            {Loader ? <div className="loader" ></div> : null}
            <div id="app">
                <AdminHeader />
                <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style " style={{backgroundcolor:"blue"}}>
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0">  Subadmin</h4>
                                </li>

                                <li className="breadcrumb-item active">View Data</li>

                                <div class="row">
                                    <div class="col-md-12  text-right">
                                        <button onClick={() => handleShow2()} className="btn btn-outline-primary"  >Add SubAdmin</button>

                                    </div>
                                </div>


                            </ul>


                            <div style={{ maxWidth: '100%' }}>
                                <MaterialTable
                                    title=""
                                    data={Data}
                                    columns={[

                                        { title: "First Name", field: "fname" },
                                        { title: "Last Name", field: "lname" },
                                        { title: "Email", field: "email", type: "string" },
                                        { title: "Contact NO", field: "contactNo", type: "numeric" },
                                        { title: "Role", field: "role", },

                                    ]}

                                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                    options={{
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        }),
                                        actionsColumnIndex: -1,
                                    }}
                                    // other props
                                    actions={[
                                        rowData => ({
                                            icon: 'edit',
                                            tooltip: 'Edit User',
                                            //   onClick: (event, rowData) => history.push("/EditSubadmin")
                                            // onClick: () => history.push("/EditSubadmin")
                                            onClick: () => {
                                                handleShow()
                                                console.log("rowData==>", rowData)
                                                SetFname(rowData.fname)
                                                SetLname(rowData.lname)
                                                SetEmail(rowData.email)
                                                SetContact(rowData.contactNo)
                                                SetRole(rowData.role)
                                                setprofileimage(rowData.profile)



                                            }

                                        }),

                                        rowData => ({
                                            icon: 'delete',
                                            tooltip: 'Delete User',
                                            onClick: () => {
                                                console.log("rowData2==>", rowData)

                                                Delete(rowData.email)
                                                // setDelSubEmail()
                                            }


                                        })
                                    ]}
                                    options={{
                                        maxBodyHeight: 400,
                                        overflowX: 'x-scroll',
                                        actionsColumnIndex: 7,
                                        headerStyle: {
                                            backgroundColor: '#222',
                                            color: "white"
                                          }
                                      }}




                                />


                            </div>



                        </section>

                    </div>
                    <AdminFooter />
                </div>
            </div>

            {/* Modal ONE Edit */}

            <Modal
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show}
                onHide={handleClose}>
                <Modal.Header   >
                    <Modal.Title>Edit SubAdmin</Modal.Title>
                    <i onClick={handleClose} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>
                    {/* Woohoo, you're reading this text in a modal! */}
                    <form>
                        <div className="form-row text-center " style={{ display: "block" }}>


                            <div className="row">
                                <div className="col-md-12 ">
                                    <center>
                                        <div className="upload_photo_main">
                                            <input
                                                onChange={e => handleInputFileChange(e, setprofileimage)}
                                                accept="image/*"
                                                className={classes.input}
                                                id="icon-button-file"
                                                type="file"

                                            />
                                            <label htmlFor="icon-button-file">
                                                <IconButton
                                                    color="#414141"
                                                    aria-label="upload picture"
                                                    component="span"
                                                    className="upload_photo_main"
                                                >
                                                    <img src={Selectedprofile ? Selectedprofile : `${(profileimage)}` !== "https://backend.fridaymart.mlnull" ? `${(profileimage)}` : 'assets/img/cam.jpg'}

                                                        style={{ minWidth: "120px", maxWidth: "120px", borderRadius: "20px" }} className="upload_photo_main" />
                                                    <div>
                                                        {Object.keys(EAddImageErr).map((key) => {
                                                            return <div style={{ color: "red" }}>{EAddImageErr[key]}</div>
                                                        })}
                                                    </div>
                                                </IconButton>
                                            </label>
                                        </div>
                                    </center>
                                </div>
                            </div>

                            {/* <div className="author-box-center">
                                            <img alt="image" src="assets/img/users/user-1.png" width="100px"   className="rounded-circle author-box-picture img-fluid" />
                                            <div className="clearfix" />


                                        </div> */}



                        </div>
                        <div className="form-row mt-4">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputFN">First Name</label>
                                <input value={Fname} onChange={e => SetFname(e.target.value)} type="text" className="form-control" id="inputFN" placeholder="Enter Your First Name" />
                                <div>
                                    {Object.keys(EfirstNameErr).map((key) => {
                                        return <div style={{ color: "red" }}>{EfirstNameErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputLN">Last Name</label>
                                <input value={Lname} onChange={e => SetLname(e.target.value)} type="text" className="form-control" id="inputLN" placeholder="Enter Your First Name" />
                                <div>
                                    {Object.keys(ElastNameErr).map((key) => {
                                        return <div style={{ color: "red" }}>{ElastNameErr[key]}</div>
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputEmail">Email</label>
                                <input value={Email} onChange={e => SetEmail(e.target.value)} type="email" className="form-control" id="inputEmail" placeholder="Email " />
                                <div>
                                    {Object.keys(EEmailErr).map((key) => {
                                        return <div style={{ color: "red" }}>{EEmailErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputPS">Password</label>
                                <input value={password} onChange={e => Setpassword(e.target.value)} type="password" className="form-control" id="inputPS" placeholder="Password" disabled/>
                                <div>
                                    {Object.keys(EPasswordErr).map((key) => {
                                        return <div style={{ color: "red" }}>{EPasswordErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCN">Contact Number</label>
                                <input value={Contact} onChange={e => SetContact(e.target.value)} type="number" className="form-control" id="inputCN" placeholder="Contact Number " />
                                <div>
                                    {Object.keys(ENumberErr).map((key) => {
                                        return <div style={{ color: "red" }}>{ENumberErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div class="form-group col-md-6 ">
                                <label for="exampleFormControlSelect1">Select Role</label>
                                <select value={Role} onChange={(e) => SetRole(e.target.value)} class="form-control" id="exampleFormControlSelect1">
                                    <option>Select Role...</option>
                                    {AllRole.map((e) => (
                                        <option value={e.role}>{e.role}</option>
                                    ))}

                                </select>
                                <div>
                                    {Object.keys(EAddRoleErr).map((key) => {
                                        return <div style={{ color: "red" }}>{EAddRoleErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>

                        <div className="form-row">



                        </div>
                        {/* <div class="row justify-content-center">
                                    <button type="submit" class="btn btn-primary btn-lg ">Update</button>
                                </div> */}

                        {/* <button type="submit" className="btn btn-primary btn-lg justify-content-center">Sign in</button> */}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={()=>UpdateSubadmin()}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Second modal for Add SUBADMIN */}



            <Modal className="btn-close"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show2}
                onHide={handleClose2}>
                <Modal.Header >
                    <Modal.Title>Add SubAdmin</Modal.Title>
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>

                    <form>
                        <div className="form-row text-center " style={{ display: "block" }}>


                            <div className="row">
                                <div className="col-md-12 ">
                                    <center>
                                        <div className="upload_photo_main">
                                            <input onChange={(e) => handleInputFileChange(e, SetAddSubProfile)}
                                                accept="image/*"
                                                className={classes.input}
                                                id="icon-button-file"
                                                type="file"

                                            />
                                            <label htmlFor="icon-button-file">

                                                <IconButton
                                                    color="#414141"
                                                    aria-label="upload picture"
                                                    component="span"
                                                    className="upload_photo_main"
                                                >
                                                    <img style={{ minWidth: "120px", maxWidth: "120px", borderRadius: "20px" }}
                                                        src={Selectedprofile ? Selectedprofile :  'assets/img/cam.jpg'}

                                                        className="upload_photo_main" />
                                                </IconButton>
                                                <div>
                                                    {Object.keys(AddImageErr).map((key) => {
                                                        return <div style={{ color: "red" }}>{AddImageErr[key]}</div>
                                                    })}
                                                </div>
                                            </label>
                                        </div>
                                    </center>
                                </div>
                            </div>


                        </div>
                        <div className="form-row mt-4">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputFN">First Name</label>
                                <input value={AddFName} onChange={e => SetAddFName(e.target.value)} type="text" className="form-control" id="inputFN" placeholder="Enter Your First Name" />
                                <div>
                                    {Object.keys(firstNameErr).map((key) => {
                                        return <div style={{ color: "red" }}>{firstNameErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputLN">Last Name</label>
                                <input value={AddLName} onChange={e => SetAddLName(e.target.value)} type="text" className="form-control" id="inputLN" placeholder="Enter Your First Name" />
                                <div>
                                    {Object.keys(lastNameErr).map((key) => {
                                        return <div style={{ color: "red" }}>{lastNameErr[key]}</div>
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputEmail">Email</label>
                                <input value={AddEmail} onChange={e => SetAddEmail(e.target.value)} type="email" className="form-control" id="inputEmail" placeholder="Email " />
                                <div>
                                    {Object.keys(EmailErr).map((key) => {
                                        return <div style={{ color: "red" }}>{EmailErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputPS">Password</label>
                                <input value={AddPassword} onChange={e => SetAddPassword(e.target.value)} type="password" className="form-control" id="inputPS" placeholder="Password" />
                                <div>
                                    {Object.keys(PasswordErr).map((key) => {
                                        return <div style={{ color: "red" }}>{PasswordErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCN">Contact Number</label>
                                <input value={AddContact} onChange={e => SetAddContact(e.target.value)} type="number" className="form-control" id="inputCN" placeholder="Contact Number " />
                                <div>
                                    {Object.keys(NumberErr).map((key) => {
                                        return <div style={{ color: "red" }}>{NumberErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div class="form-group col-md-6 ">
                                <label for="exampleFormControlSelect1">Select Role</label>
                                <select onClick={(e) => SetAddRole(e.target.value)} class="form-control" id="exampleFormControlSelect1">
                                <option>Select Role...</option>
                                    {AllRole.map((e) => (
                                        <option value={e.role}>{e.role}</option>
                                    ))}

                                </select>
                                <div>
                                    {Object.keys(AddRoleErr).map((key) => {
                                        return <div style={{ color: "red" }}>{AddRoleErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>

                        <div className="form-row">



                        </div>
                        {/* <div class="row justify-content-center">
                                    <button type="submit" class="btn btn-primary btn-lg ">Update</button>
                                </div> */}

                        {/* <button type="submit" className="btn btn-primary btn-lg justify-content-center">Sign in</button> */}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => AddSubmin()}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}