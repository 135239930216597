import React,{useState,useEffect} from "react"
import AdminHeader from "../components/adminheader/AdminHeader"
import AdminFooter from "../components/adminfooter/AdminFooter"
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";


export default function AdminDashboard() {

    const [Loader, setLoader] = useState(false)
    const [Adash, setAdash] = useState([])
    console.log("sdash",Adash)



    const Token = localStorage.getItem('Token');

    useEffect(() => {
        GetAdminDashboard()

    }, [])



  // VIEW SuperADmin Dashb API

  const GetAdminDashboard = () => {
    setLoader(true);

    getApiWithToken(`${BaseUrl.Admin_Url}/GetDashboardDetails`, "", Token)
        .then(({ data }) => {
            setLoader(false);
            if (data.status) {
                setAdash(data)
                console.log("Admin data==>", data)
                // setdisable(true);
                // SetUstatus
                // $(".loader").hide();

            } else {
                // $(".loader").hide();
                // Swal.fire({
                //     title: "Opps",
                //     text: data.message,
                //     icon: "error"
                // })
            }
        }).catch(function (e) {
            // $(".loader").hide();
            Swal.fire({
                title: "Opps",
                text: e.message,
                icon: "error"
            })
        })

}




    return (
        <>
         {Loader ? <div className="loader" /> : null}

            <div id="app">
                <AdminHeader/>
                <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0">Dashboard</h4>
                                </li>

                            </ul>


                            <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/1.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Active Products </h6>
                                                        <h4 className="text-right">{Adash.ActiveProducts}<span>
                                                        </span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart2" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/vendors.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Pending Products</h6>
                                                        <h4 className="text-right"><span>{Adash.PendingProducts}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart1"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/active.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Total Products</h6>
                                                        <h4 className="text-right"><span>{Adash.TotalProducts}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart1"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/1.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">New Customers</h6>
                                                        <h4 className="text-right"><span>{Adash
                                                        .Customers}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart2" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/vendors.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Active Vendors</h6>
                                                        <h4 className="text-right"><span>{Adash.ActiveVendor}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart1"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/active.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Pending Vendors</h6>
                                                        <h4 className="text-right"><span>{Adash.PendingVendor}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart1"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">

                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/2.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Orders Received</h6>
                                                        <h4 className="text-right"><span>{Adash.Orders}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart4" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/4.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Revenue Today</h6>
                                                        <h4 className="text-right"><span>{Adash.Revenue}</span>
                                                        </h4>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>

                    </div>
<AdminFooter/>
                </div>
            </div>


        </>
    )
}