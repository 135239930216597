/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, history } from "react";
import Swal from 'sweetalert2';
import Icon from './babylonIcon.png';
import profileLogo from './profile.jpg';
import BaseUrl from "../../../config/BaseUrl"
import image_url from "../../../config/BaseUrl"
// import LOGO from "./SFlogo.png"
import LOGO2 from "./HUGO-06.png"
// import LOGO3 from "./logo3.png"


import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../config/Api/FakeuserApi";


import { Link, NavLink, useHistory } from "react-router-dom";



export default function Header() {
    const history = useHistory()
    const [Loader, setLoader] = useState(false);
    const [Data, setData] = useState([]);




    const Token = localStorage.getItem('SellerToken');
    const SellerData = JSON.parse(localStorage.getItem("seller"));


    useEffect(() => {

        if (localStorage.getItem("SellerToken") == null) {
            history.push("/SellerLogin")
        }

        else {

            GetProfileData()
        }


    }, [])




    const GetProfileData = () => {
        setLoader(true);

        getApiWithToken(`${BaseUrl.base_url}/GetSellerProfile`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    localStorage.setItem('seller', JSON.stringify(data.data));
                    setData(data.data)
                    // const Data = data.data
                    console.log("Data=====", data.data)


                } else {
                    if (data.message == "tokenexpire") {
                        history.push("/SellerLogin")
                    }
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }


    let removeClass = () => {


        console.log("running")

        var x = document.getElementsByTagName("BODY")[0].classList;

        if (x.contains("sidebar-gone")) {

            x.remove('sidebar-gone')
            x.add('sidebar-show')

        }
        else {

            if (x.contains("sidebar-mini")) {

                x.remove('sidebar-mini')
            }


            else {

                x.add('sidebar-mini')

            }


        }


    }



    return (
        <>
            <div className="navbar-bg" />
            <nav className="navbar navbar-expand-lg main-navbar sticky">
                <div className="form-inline mr-auto">
                    <ul class="navbar-nav mr-3">
                        <li><a href="#" class="nav-link nav-link-lg collapse-btn"> <i onClick={() => removeClass()} className="fa fa-bars" style={{ color: "grey" }}></i></a></li>
                        <li>
                        </li>
                    </ul>
                </div>

                <ul className="navbar-nav navbar-right">
                    <li><a href="#" className="nav-link nav-link-lg fas fa-expand fullscreen-btn " title="Full Screen" style={{ color: "grey", fontSize: "23px", marginRight: "5px", paddingTop: "28px" }}>
                        <i data-feather="maximize" />
                    </a></li>
                    <li className="dropdown dropdown-list-toggle"> <a href="#" data-toggle="dropdown" className=" nav-link-lg far fa-envelope-open " title="Messages" style={{ color: "grey", fontSize: "23px", marginTop: "30px" }}> <i data-feather="mail" className="mailAnim" />
                        <span className="badge headerBadge1">
                        </span> </a>
                        <div className="dropdown-menu dropdown-list dropdown-menu-right pullDown">
                            <div className="dropdown-header">
                                Messages
                                <div className="float-right">
                                    <a href="#">Mark All As Read</a>
                                </div>
                            </div>
                            <div className="dropdown-list-content dropdown-list-message">
                                <a href="#" className="dropdown-item"> <span className="dropdown-item-avatar
											text-white"> <img alt="image" src="assets/img/users/user-1.png" className="rounded-circle" />
                                </span> <span className="dropdown-item-desc"> <span className="message-user">John
                                    Deo</span>
                                        <span className="time messege-text">Please check your mail !!</span>
                                        <span className="time">2 Min Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-avatar text-white">
                                    <img alt="image" src="assets/img/users/user-2.png" className="rounded-circle" />
                                </span> <span className="dropdown-item-desc"> <span className="message-user">Sarah
                                    Smith</span> <span className="time messege-text">Request for leave
                                        application</span>
                                        <span className="time">5 Min Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-avatar text-white">
                                    <img alt="image" src="assets/img/users/user-5.png" className="rounded-circle" />
                                </span> <span className="dropdown-item-desc"> <span className="message-user">Jacob
                                    Ryan</span> <span className="time messege-text">Your payment invoice is
                                        generated.</span> <span className="time">12 Min Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-avatar text-white">
                                    <img alt="image" src="assets/img/users/user-4.png" className="rounded-circle" />
                                </span> <span className="dropdown-item-desc"> <span className="message-user">Lina
                                    Smith</span> <span className="time messege-text">hii John, I have upload
                                        doc
                                        related to task.</span> <span className="time">30
                                            Min Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-avatar text-white">
                                    <img alt="image" src="assets/img/users/user-3.png" className="rounded-circle" />
                                </span> <span className="dropdown-item-desc"> <span className="message-user">Jalpa
                                    Joshi</span> <span className="time messege-text">Please do as specify.
                                        Let me
                                        know if you have any query.</span> <span className="time">1
                                            Days Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-avatar text-white">
                                    <img alt="image" src="assets/img/users/user-2.png" className="rounded-circle" />
                                </span> <span className="dropdown-item-desc"> <span className="message-user">Sarah
                                    Smith</span> <span className="time messege-text">Client Requirements</span>
                                        <span className="time">2 Days Ago</span>
                                    </span>
                                </a>
                            </div>
                            <div className="dropdown-footer text-center">
                                <a href="#">View All <i className="fas fa-chevron-right" /></a>
                            </div>
                        </div>
                    </li>
                    <li className="dropdown dropdown-list-toggle"><a href="#" data-toggle="dropdown" className=" notification-toggle   far fa-bell" title="Notifications" style={{ color: "grey", fontSize: "23px", marginTop: "30px" }}><i data-feather="bell" />
                    </a>
                        <div className="dropdown-menu dropdown-list dropdown-menu-right pullDown">
                            <div className="dropdown-header">
                                Notifications
                                <div className="float-right">
                                    <a href="#">Mark All As Read</a>
                                </div>
                            </div>
                            <div className="dropdown-list-content dropdown-list-icons">
                                <a href="#" className="dropdown-item dropdown-item-unread"> <span className="dropdown-item-icon l-bg-orange text-white"> <i className="far fa-envelope" />
                                </span> <span className="dropdown-item-desc"> You got new mail, please check. <span className="time">2 Min
                                    Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-icon l-bg-purple text-white"> <i className="fas fa-bell" />
                                </span> <span className="dropdown-item-desc"> Meeting with <b>John Deo</b> and <b>Sarah Smith </b> at
                                    10:30 am <span className="time">10 Hours
                                        Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-icon l-bg-green text-white"> <i className="far fa-check-circle" />
                                </span> <span className="dropdown-item-desc"> Sidebar Bug is fixed by Kevin <span className="time">12
                                    Hours
                                    Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-icon bg-danger text-white"> <i className="fas fa-exclamation-triangle" />
                                </span> <span className="dropdown-item-desc"> Low disk space error!!!. <span className="time">17 Hours
                                    Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-icon bg-info text-white"> <i className="fas
												fa-bell" />
                                </span> <span className="dropdown-item-desc"> Welcome to Gati
                                    template! <span className="time">Yesterday</span>
                                    </span>
                                </a>
                            </div>
                            <div className="dropdown-footer text-center">
                                <a href="#">View All <i className="fas fa-chevron-right" /></a>
                            </div>
                        </div>
                    </li>
                    <li className="dropdown"><a href="#" data-toggle="dropdown" className=" nav-link far fa-user" style={{ color: "grey", fontSize: "23px", marginLeft: "5px", paddingTop: "28px" }}>  <span className="d-sm-none d-lg-inline-block" /></a>
                        <div className="dropdown-menu dropdown-menu-right pullDown">

                            <div className="dropdown-title">Hello {SellerData?.First_name + " " + SellerData?.last_name}</div>
                            <a href="/profile" className="dropdown-item has-icon"> <i className="far
										fa-user" /> Profile
                            </a>
                            {/* <a href="timeline.html" className="dropdown-item has-icon"> <i className="fas fa-bolt" />
                                Activities */}
                            {/* </a> <a href="#" className="dropdown-item has-icon"> <i className="fas fa-cog" />
                                Settings
                            </a> */}
                            <div className="dropdown-divider" />
                            {/*
                            <NavLink to="/profile" className="dropdown-item has-icon"><i class="fas fa-user"></i>
                                <span>Profile</span>
                            </NavLink> */}
                            <NavLink to="/SellerLogin" onClick={() => localStorage.clear()} className="dropdown-item has-icon text-danger"> <i className="fas fa-sign-out-alt" />
                                Logout
                            </NavLink>
                        </div>
                    </li>
                </ul>
            </nav>
            <div className="main-sidebar sidebar-style-2">
                <aside id="sidebar-wrapper">
                    <div className="sidebar-brand">
                        <a className="customPointer" >
                            <img alt="image" src={'assets/louralogo/Loura-09.png'} style={{ marginRight: 4,height:"50px" }} className="header-logo" />
                            <span className="logo-name"> </span>
                        </a>
                    </div>
                    <div className="sidebar-user">
                        <div className="sidebar-user-picture">
                            {/* <img alt="image" src={image_url.image_url(SellerData.profile)} /> */}
                        </div>
                        <div className="sidebar-user-details">
                            <div className="user-name">{SellerData?.First_name + " " + SellerData?.last_name}</div>
                            <div className="user-role"></div>
                            <div className="sidebar-userpic-btn">
                                <a href="profile.html" data-toggle="tooltip" title="Profile">
                                    <i data-feather="user" />
                                </a>
                                <a href="email_inbox.html" data-toggle="tooltip" title="Mail">
                                    <i data-feather="mail" />
                                </a>
                                <a href="chat.html" data-toggle="tooltip" title="Chat">
                                    <i data-feather="message-square" />
                                </a>
                                <a href="login.html" data-toggle="tooltip" title="Log Out">
                                    <i data-feather="log-out" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <ul className="sidebar-menu">

                        {/* <li className="dropdown">
                            <NavLink to=""  className="menu-toggle nav-link has-dropdown"><i class="fas fa-dolly-flatbed"></i><span>IVENTORY</span></NavLink>
                            <ul className="dropdown-menu">
                                <li><NavLink to="#" onClick={()=>ProductM()} activeClassName="active" className="nav-link" >Manage Inventory</NavLink></li>
                                <li><NavLink to="#" onClick={()=>ProductA()} activeClassName="active"  className="nav-link" >Add Product</NavLink></li>
                                {/* <li><a className="nav-link" href="email-compose.html">Inventory History</a></li>
                                <li><a className="nav-link" href="email-read.html">Inventory Planing</a></li> */}
                        {/* <li><a className="nav-link" href="email-read.html">Add Product via Upload</a></li>
                                <li><a className="nav-link" href="email-read.html">Sell Globally</a></li>
                                <li><a className="nav-link" href="email-read.html">Upload and Manage Videos</a></li> */}
                        {/* </ul> */}
                        {/* </li> */}
                        <li className="">
                            <NavLink to="/newDashboard" activeClassName="active" className="nav-link"><i class="fas fa-chart-line"></i><span>DASHBOARD</span></NavLink>
                        </li>
                        <li className="">
                            <NavLink to="/NewAddProduct" activeClassName="active" className="nav-link"><i class="fas fa-plus"></i><span>ADD PRODUCT</span></NavLink>
                        </li>
                        <li className="">
                            <NavLink to="/productManagement" activeClassName="active" className="nav-link"><i class="fas fa-dolly-flatbed"></i><span>MANAGE PRODUCTS</span></NavLink>
                            <NavLink to="/ManageProductimg" activeClassName="active" className="nav-link"><i class="fas fa-image"></i><span>MANAGE IMAGES</span></NavLink>
                            <NavLink to="/AffilateMarket" activeClassName="active" className="nav-link"><i className="fas fa-bullhorn"></i><span>AFFILATE MARKKETING </span></NavLink>
                            {/* <NavLink to="/OrderDetails"   activeClassName="active" className="nav-link"><i className= "fas fa-bullhorn"></i><span> OrderDetails </span></NavLink> */}



                        </li>
                        <li className="">
                            <NavLink to="/orders" activeClassName="active" className="nav-link"><i class="fas fa-sort-amount-up"></i><span>ORDERS</span></NavLink>
                        </li>
                        <li className="">
                            <NavLink to="/profile" activeClassName="active" className="nav-link"><i class="fas fa-user"></i><span>PROFILE</span></NavLink>
                        </li>

                        {/* <li className="">
                            <NavLink to="#"  activeClassName="active" className="nav-link"><i class="fas fa-align-left"></i><span>ORDERS</span></NavLink>
                        </li> */}
                        <li className="">
                            <NavLink to="/SellerLogin" activeClassName="active" onClick={() => localStorage.clear()} className="nav-link"><i class="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
                        </li>

                    </ul>
                </aside>
            </div>
        </>
    )
}