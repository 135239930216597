import React, { useState, useEffect } from "react"

export default function SuperAdminFooter() {

    return (
        <>
            <footer className="main-footer">
                <div className="footer-left">
                    Copyright &copy; 2022 <div className="bullet"></div> Design By <a href="#">HNH TCH SOLUTIONS</a>
                </div>
                <div className="footer-right">
                </div>
            </footer>
        </>
    )
}