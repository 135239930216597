import React, { useState, useEffect } from "react"
import AdminHeader from "../components/adminheader/AdminHeader"
import AdminFooter from "../components/adminfooter/AdminFooter"
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from 'material-table'
import { useHistory } from "react-router";
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import image_url from "../../../../config/BaseUrl"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import IconButton from "@material-ui/core/IconButton";

// import{ MaterialTable} from "@material-ui/core/MaterialTable";
const background={
    color: "white",
    backgroundColor:"rgba(0,0,0,.65)"
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));

export default function ViewRoles() {
    const history = useHistory();
    const [selectedRow, setSelectedRow] = useState(null);

    // GETALlROLES STATES
    const [GetRoles, SetGetRoles] = useState([])
    const [Loader, setLoader] = useState(false)

    // AddROLES Modal States1
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const classes = useStyles();
    const [Role, SetRole] = useState("")
    const [AddRoleErr, SetAddRoleErr] = useState("")




    // AddRole states

    // Edit api states Modal 2
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [EditRole, SetEditRole] = useState("")
    const [EditId, SetEditId] = useState("")
    const [AddERoleErr, SetAddERoleErr] = useState({})


    //DELETR STATE
    // const [DeleteId,setDeleteId]=useState("")
    // const [SelectedThumbnail,setSelectedThumbnail]=useState(null)
    // const [UpdateThumbnail,setUpdateThumbnail]=useState(null)
    // const [AddSubRole, SetAddSubRole] = useState("")






    // gETtoken from local storage
    const Token = localStorage.getItem('Token');
    useEffect(() => {
        ViewAllRoles()

    }, [])




    // VIEW ALL ROLES API
    const ViewAllRoles = () => {
        setLoader(true);

        getApiWithToken(`${BaseUrl.Admin_Url}/Add_role`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    SetGetRoles(data.data)
                    // console.log("dataaVIEWROLES==>", data)
                    // setdisable(true);
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }

    // ADDRole API

    const AddNewRole = () => {
        const isValid = formValidation();
        if (isValid== true){
        setLoader(true);
        var formdata = new FormData();
        formdata.append("role", Role);
        PostApi(`${BaseUrl.Admin_Url}/Add_role`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("AddDATA==>", data)
                    SetRole("")
                    ViewAllRoles()
                    handleClose()

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })



    }else{
        formValidation()

    }
    }

    const formValidation = () => {
        const AddRoleErr = {};

        let isValid = true;

        if (Role.length == 0) {
            AddRoleErr.firstNamenull = "Required Role";
            isValid = false;
        }

        SetAddRoleErr(AddRoleErr);
        return isValid;
    }


    // Edit Role Api

    const UpdateRole = () => {
        const isValid = UpdateformValidation();
        if (isValid== true){
        setLoader(true);
        var formdata = new FormData();

        formdata.append("role", EditRole);
        formdata.append("id", EditId);
        // formdata.append("profile",AddSubRole);




        PutApi(`${BaseUrl.Admin_Url}/Add_role`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("dataaupdate==>", data)
                    ViewAllRoles()
                    handleClose2()

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    }else{
        UpdateformValidation()
    }
}

const UpdateformValidation = () => {
    const AddERoleErr = {};

    let isValid = true;

    if (EditRole.length == 0) {
        AddERoleErr.firstNamenull = "Required Role";
        isValid = false;
    }

    SetAddERoleErr(AddERoleErr);
    return isValid;
}


    // DELETE API

    const Delete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete it",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteRole(id)
            }
        })

    }


    const DeleteRole = (id) => {
        // console.log("token==>",Token)

        setLoader(true);
        var formdata = new FormData();
        formdata.append("id", id);

        DeleteApi(`${BaseUrl.Admin_Url}/Add_role`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("dELETE==>", data)
                    ViewAllRoles()
                    handleClose2()

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    }


    return (
        <>
           {Loader ?<div class="loader"></div>:null}
            <div id="app">
                <AdminHeader />
                <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0">  Subadmin</h4>
                                </li>

                                <li className="breadcrumb-item active">View Roles</li>
                                <div class="row">
                                    <div class="col-md-12  text-right">
                                        <button onClick={() => handleShow()} type="button" className="btn btn-outline-primary">Add Role</button>

                                    </div>
                                </div>
                            </ul>

                            <div style={{ maxWidth: '100%' }}>
                                <MaterialTable
                                    data={GetRoles}
                                    title=""
                                    columns={[

                                        { title: "Role", field: "role", },

                                    ]}


                                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                    options={{
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        }),
                                        actionsColumnIndex: -1,
                                        exportButton: true
                                    }}
                                    // other props
                                    actions={[
                                        rowData => ({
                                            icon: 'edit',
                                            tooltip: 'Edit User',

                                            onClick: () => {
                                                handleShow2()

                                                SetEditRole(rowData.role)
                                                SetEditId(rowData.id)

                                            }

                                        }),

                                        rowData => ({
                                            icon: 'delete',
                                            tooltip: 'Delete User',
                                            onClick: () => {
                                                console.log("rowData2==>", rowData)

                                                Delete(rowData.id)
                                                // setDeleteId()
                                            }
                                        })

                                    ]}
                                    options={{
                                        maxBodyHeight: 400,
                                        overflowX: 'x-scroll',
                                        actionsColumnIndex: 7,
                                        headerStyle: {
                                            backgroundColor: '#222',
                                            color: "white"
                                          }
                                      }}
                                />


                            </div>


                        </section>

                    </div>
                    <AdminFooter />
                </div>
            </div>

            <Modal
                size="md"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show}
                onHide={handleClose}>
                <Modal.Header   >
                    <Modal.Title>Add Roles</Modal.Title>
                    <i onClick={handleClose} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>

                    <form>
                        <div className="form-row text-center " style={{ display: "block" }}>





                        </div>
                        <div className="form-row mt-4">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputAR">Add Role</label>

                                <input value={Role} onChange={e => SetRole(e.target.value)} type="text" className="form-control" id="inputAR" placeholder=" Assign User Role" />
                                <div>
                                    {Object.keys(AddRoleErr).map((key) => {
                                        return <div style={{ color: "red" }}>{AddRoleErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>


                        {/*
                                <div class="row justify-content-center">
                                    <button type="submit" class="btn btn-primary btn-lg ">Add</button>
                                </div> */}

                        {/* <button type="submit" className="btn btn-primary btn-lg justify-content-center">Sign in</button> */}
                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => AddNewRole()} >
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>



            {/* Modal 2  */}


            <Modal
                size="md"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show2}
                onHide={handleClose2}>
                <Modal.Header   >
                    <Modal.Title>Edit Role</Modal.Title>
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>

                    <form>

                        <div className="form-row mt-4">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputAR">Edit Role</label>
                                <input value={EditRole} onChange={e => SetEditRole(e.target.value)} type="text" className="form-control" id="inputAR" placeholder=" Assign User Role" />
                                <div>
                                    {Object.keys(AddERoleErr).map((key) => {
                                        return <div style={{ color: "red" }}>{AddERoleErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>


                        {/*
                                <div class="row justify-content-center">
                                    <button type="submit" class="btn btn-primary btn-lg ">Add</button>
                                </div> */}

                        {/* <button type="submit" className="btn btn-primary btn-lg justify-content-center">Sign in</button> */}
                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => UpdateRole()}  >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}