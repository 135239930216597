import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import BaseUrl from "../../config/BaseUrl"
import image_url from "../../config/BaseUrl"
import Swal from 'sweetalert2'
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../config/Api/FakeuserApi";
import { connect } from "react-redux";
import { checkEmailorPhone, setValue } from "../../config/Redux/Actions/Index";




const MembershipName=(props)=> {
    const history = useHistory();
    const [Loader, setLoader] = useState(false)
    const Style = {
        marginInline: "100px"
    }
    const [MembershipName, SetMembershipName] = useState([])
    // const [MemberId, SetMemberId] = useState("")
    // console.log("memid",MemberId)


    const Token = localStorage.getItem('SuperToken');
    useEffect(() => {
        ViewAllMembershipName()

    }, [])
    const Memberid =(id)=>{
        console.log("id",id)

        setLoader(true)
        props.setValue("Membershipid", id)
        history.push('/signup')

    }

     // VIEW ALL PACKAGES API

     const ViewAllMembershipName = () => {
        setLoader(true);
        getApiWithToken(`${BaseUrl.base_url}/GetMembershipPalns`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {


                    console.log("membershipPlain==>", data)
                    SetMembershipName(data.data)


                } else {

                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {

                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }



    return (


        <>

                {Loader ? <div className="loader" ></div> : null}
            <div class="section-body mt-4 my-4">
                <h3 className="text-center ">MEMBERSHIPS</h3>
                <div class=" container_fluid mt-5 " style={Style}>
                    <div className="row">
                        {MembershipName.map((e) =>

                            <div class=" col-md-4 ">
                                <div class="pricing pricing-highlight">
                                    <div class="pricing-title">
                                        {e.Membership_Name}
                                    </div>
                                    <div class="pricing-padding">
                                        <div class="pricing-price">
                                            <div>$ {e.Membership_Price} </div>
                                            <div> <b className="pricing pricing-highlight">YEARLY</b></div>
                                        </div>
                                        <div class="pricing-details">
                                            <div class="pricing-item">
                                                <div class="pricing-item-icon bg-primary"><i class="fas fa-check"></i></div>
                                                <div class="pricing-item-label"><b>Membership_Description:</b> {e.Membership_Description.slice(0,60)}....</div>
                                            </div>
                                            <div class="pricing-item">
                                                <div class="pricing-item-icon bg-primary"><i class="fas fa-check"></i></div>
                                                <div class="pricing-item-label"><b>Membership_DurationStart:</b> {e.Membership_DurationStart}</div>
                                            </div>
                                            <div class="pricing-item">
                                                <div class="pricing-item-icon bg-primary"><i class="fas fa-check"></i></div>
                                                <div class="pricing-item-label"> <b>Membership_DurationEnd:</b> {e.Membership_DurationEnd}</div>
                                            </div>
                                            <div class="pricing-item">
                                                <div class="pricing-item-icon bg-primary"><i class="fas fa-check"></i></div>
                                                <div class="pricing-item-label"> <b>Country:</b> {e.Country}</div>
                                            </div>
                                            {/* <div class="pricing-item">
                                                <div class="pricing-item-icon bg-primary"><i class="fas fa-check"></i></div>
                                                <div class="pricing-item-label"><b>Status:</b> {e.Status}</div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-md-12 text-center">

                                                    <button onClick={() => Memberid(e.id)} type="button" class=" btn-lg btn btn-outline-primary">SignUp</button>
                                                </div>


                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </div>

            {/* <img style={{ width: "100%", height: "100%", zIndex: "-1", position: "fixed" }} src="assets/img/Babylon-Landing Page.jpg" />
            <div className="container-fluid">
                <div className="row pt-3">
                    <div className="col-md-10"></div>
                    <div className="col-md-2">
                        <button onClick={() => history.push('/login')} className="btn btn-warning rounded-pill m-2">Login</button>
                        <button onClick={() => history.push('/signup')} className="btn btn-warning rounded-pill m-2">Signup</button>
                    </div>
                </div>
                <div style={{ marginTop: "18%" }} className="row">
                    <div className="col-md-12">
                    <p style={{ width:'30%', marginLeft: "6%", color:'white' }}>More than half the units sold in our stores are from independent sellers.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button style={{ width: "10%", marginLeft: "6%" }} onClick={() => history.push('/signup')} className="btn btn-warning rounded-pill p-2">Signup</button>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-md-12">
                        <p style={{ width:'30%', marginLeft: "6%", color:'white' }}>$19.99 a month + selling fees</p>
                    </div>
                    </div>
            </div> */}
        </>
    )
}

const mapStateToProps = (state) => ({
    data: state.Register

})

const mapDispatchToProps = {
    checkEmailorPhone,
    setValue
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipName)