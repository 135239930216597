import React, { useState, useEffect } from "react";
import Header from '../../../components/header/header'
import Footer from '../../../components/footer/footer'
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import MaterialTable from 'material-table'
import image_url from "../../../../config/BaseUrl"
import { Link, NavLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import { RemoveRedEye } from "@material-ui/icons"
import { style } from "@mui/system";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));




export default function OrderDetails(props) {


    console.log("full props",props.location.state)

    // const [GetAccounts, SetGetAccounts] = useState([])
    const [Loader, setLoader] = useState(false)

    const [selectedRow, setSelectedRow] = useState(null);
    const [Getalldata, setGetalldata] = useState([]);
    useEffect(() => {

        GetOrderDetails()
    }, [])

    console.log("Getalldata",Getalldata)


    const GetOrderDetails = () => {
        setLoader(true);

        getApiWithToken(`${BaseUrl.base_url}/GetDetailsOrder?OrderNo=${props.location.state}`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    // console.log("orderdetails",data)
                    setGetalldata(data.data)


                    // setdisable(true);
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }













    const Token = localStorage.getItem('SellerToken');



    return (
        <>
            {Loader ? <div className="loader" /> : null}
            <div id="app">
                <div class="main-wrapper main-wrapper-1">
                    <Header />
                    <div className="main-content">
                        <section className="section">
                        <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0"> Home</h4>
                                </li>

                                <li className="breadcrumb-item active">Orders</li>
                                <li className="breadcrumb-item active">Details</li>


                            </ul>
                            <div class="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h4>Order Detail for Order No.{Getalldata.OrderNo}
</h4>
                                        </div>
                                        <div className="card-body">
                                            <section className="section">
                                                <div className="section-body">
                                                    <div className="row clearfix">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <div className="card">


                                                                <div className="card-body">


                                                                </div>

                                                                <div className="card-body" style={{ paddingTop: 0 }}>

                                                                    <div className="card-body" style={{ padding: 5 }}>
                                                                        <div class="row" style={{ marginLeft: 10 }}>
                                                                            <div className="col-md-4">
                                                                                <h5>Customer Information</h5>
                                                                                <hr style={{borderWidth:"4px"}}/>
                                                                                <div className="row">
                                                                                    <div className="col-md-8">
                                                                                    <small style={{ fontSize: "13px" }}> Date  <p style={{ fontSize: "15px", fontWeight: "bold" }}>{Getalldata.Created}</p></small>

                                                                             <small style={{ fontSize: "13px" }}>Customer</small>
                                                                             <p style={{ fontSize: "15px", fontWeight: "bold" }}>{Getalldata.FullName}</p>
                                                                             <small style={{ fontSize: "13px" }}>Contact Mobile Phone Number</small>
                                                                             <p style={{ fontSize: "15px", fontWeight: "bold" }}>{Getalldata.CustomerNumber}</p>
                                                                             <small style={{ fontSize: "13px" }}>Payment Method</small>
                                                                             <p style={{ fontSize: "15px", fontWeight: "bold" }}>{Getalldata.PaymentMethod}</p>

                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                    <small style={{ fontSize: "13px" }}>Subtotal</small>
                                                          <p style={{ fontSize: "15px", fontWeight: "bold" }}>{Getalldata.Subtotal}</p>



                                                                             <small style={{ fontSize: "13px" }}>Grand Total</small>
                                                                             <p style={{ fontSize: "15px", fontWeight: "bold" }}>{Getalldata.Total}</p>


                                                                                    </div>

                                                                                </div>

                                                                            </div>

                                                                            <div className="col-md-4">
                                                                            <h5>Transaction Information</h5>
                                                                            <hr style={{borderWidth:"4px"}}/>





                                                                            </div>


                                                                            <div className="col-md-4">
                                                                            <h5>Shipping Address</h5>
                                                                            <hr style={{borderWidth:"4px"}}/>
                                                                            <small style={{ fontSize: "13px" ,fontWeight: "bold" }}>{Getalldata.FullName}</small>
                                                                             <p style={{ fontSize: "13px"  }}>
                                                                                {Getalldata.Address} {Getalldata.Area} {Getalldata.State} {Getalldata.Province}</p>


                                                                            </div>



                                                                        </div>
                                                                        <h4 className="mt-3"  style={{ color:"black" ,fontWeight: "bold" }}>ITEMS</h4>

                                                                        <div className="mt-4" style={{ maxWidth: '100%' }}>
                                                                            <MaterialTable
                                                                         data={Getalldata.SpecificOrderDetails}

                                                                                title=""
                                                                                columns={[



                                                                                    { title: 'Item ID', field:"",  render: rowData =>rowData.OrderNo },

                                                                                    { title: 'Product Name', field: '', render: rowData =>rowData.PrdouctName },
                                                                                    { title: 'Total', field: '',render: rowData =>rowData.Total },
                                                                                    // { title: 'Refunds', field: '',render: rowData =>rowData.OrderNo },
                                                                                    // { title: 'Promotions', field: '' },
                                                                                    // { title: 'Voucher', field: '' },
                                                                                    // { title: 'Shipping', field: '' },
                                                                                    // { title: 'Status', field: '' },



                                                                                ]}

                                                                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                                                                options={{
                                                                                    rowStyle: rowData => ({
                                                                                        selectableRow: false,
                                                                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                                                    }),
                                                                                    actionsColumnIndex: -1,
                                                                                }}

                                                                                options={{
                                                                                    maxBodyHeight: 400,
                                                                                    overflowX: 'x-scroll',
                                                                                    actionsColumnIndex: 7,
                                                                                    headerStyle: {
                                                                                        backgroundColor: '#222',
                                                                                        color: "white"
                                                                                    }
                                                                                }}


                                                                            />
                                                                        </div>

                                                                        <h4 className="mt-3" style={{ color:"black" ,fontWeight: "bold" }}>ITEMS HISTORY</h4>
                                                                        <div className="mt-2" style={{ maxWidth: '100%' }}>
                                                                            <MaterialTable
                                                                            data={Getalldata.OrderStatus}

                                                                                title=""
                                                                                columns={[



                                                                                    { title: 'Item ID', field:"",  render: rowData =>rowData.id },
                                                                                    // { title: 'Daraz  SKU', field: '',render: rowData =>rowData.id },
                                                                                    { title: 'Status', field: '',render: rowData =>rowData.OrderStatus },
                                                                                    { title: 'Created At', field: '',render: rowData =>rowData.Created},




                                                                                ]}


                                                                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                                                                options={{
                                                                                    rowStyle: rowData => ({
                                                                                        selectableRow: false,
                                                                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                                                    }),
                                                                                    actionsColumnIndex: -1,
                                                                                }}



                                                                                options={{
                                                                                    maxBodyHeight: 400,
                                                                                    overflowX: 'x-scroll',
                                                                                    actionsColumnIndex: 7,
                                                                                    headerStyle: {
                                                                                        backgroundColor: '#222',
                                                                                        color: "white"
                                                                                    }
                                                                                }}


                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>


            {/* Modal 2 edit category  */}


        </>
    )
}