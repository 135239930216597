import React, { useState, useEffect } from "react";
import SuperAdminHeader from "../components/SAheader/Header"
import SuperAdminFooter from "../components/SAfooter/Footer"
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import MaterialTable from 'material-table'
import image_url from "../../../../config/BaseUrl"
import { Link, NavLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import { RemoveRedEye } from "@material-ui/icons"
import { color } from "@mui/system";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));
const background={
    color: "white",
    backgroundColor:"rgba(0,0,0,.65)"
}

const btn ={
    backgroundColor:"rgba(0,0,0,.65)",
    color:"white",


}


export default function InventoryManag() {
    const [Loader, setLoader] = useState(false)

    const [selectedRow, setSelectedRow] = useState(null);
    const [PendingProducts, SetPendingProducts] = useState([]);
    const [Active, SetActive] = useState(true);
    const [buttonshow, Setbuttonshow] = useState(false);




    const history = useHistory()

    const Token = localStorage.getItem('SuperToken');
    useEffect(() => {
        PendingPro("Pending")
    }, [])

    const PendingPro = (status) => {
        if (status == "Online") {
            Setbuttonshow(false)
            SetActive(false)
        }
        if (status == "OutofStock") {
            Setbuttonshow(false)
            SetActive(false)
        }

        if (status == "Inactive") {
            Setbuttonshow(false)
            SetActive(false)
        }

        if (status == "Suspended") {
            Setbuttonshow(false)
            SetActive(false)
        }

        if (status == "Deleted") {
            Setbuttonshow(false)
            SetActive(false)

        }
        if (status == "Incomplete") {
            Setbuttonshow(false)
            SetActive(false)
        }

        if (status == "All") {

            SetActive(false)
            Setbuttonshow(false)
        }

        if (status == "Pending") {

            // SetActive(false)
            Setbuttonshow(true)
        }

        setLoader(true);

        getApiWithToken(`${BaseUrl.Super_Admin_Url}/GetProduct?Product_Status=${status}`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("dataget", data)
                    SetPendingProducts(data.data)


                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })



    }

    // UPDATE STATUS PUT API

    const UpdateProStatus = (id, Product_Status) => {
        setLoader(true);
        var formdata = new FormData();

        formdata.append("id", id);
        formdata.append("Product_Status", "Online");
        // formdata.append("Percentage",EditPercentage);

        PutApi(`${BaseUrl.Super_Admin_Url}/GetProduct`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("view==>", data)
                    PendingPro("Pending")
                    // handleClose2()
                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    }






    return (
        <>
            {Loader ? <div className="loader" /> : null}
            <div id="app">
                <div class="main-wrapper main-wrapper-1">
                    <SuperAdminHeader />
                    <div className="main-content">
                        <section className="section">
                            <div class="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h4>Inventory Management</h4>
                                        </div>
                                        <div className="card-body">
                                            <section className="section">
                                                <div className="section-body">
                                                    <div className="row clearfix">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <div className="card">
                                                                {/* <div className="card-header">
                                                                    <h4>Vertical Layout</h4>
                                                                </div> */}
                                                                <div className="card-body explainBlock">
                                                                    <i style={{ fontSize: 16 }} class="fas fa-exclamation-circle fa-lg"></i>
                                                                    <span style={{ fontSize: 16, fontWeight: "bold" }}> Explaination</span>
                                                                </div>
                                                                <div className="card-body">
                                                                    <button type="button" onClick={() => PendingPro("All")} class="expTopbar btn btn-outline-primary">All Products</button>


                                                                    <button type="button" onClick={() => PendingPro("Pending") } class={Active ? "expTopbar btn btn-secondary active" : "expTopbar btn btn-outline-primary  "} >Pending Products</button>
                                                                    <button type="button" onClick={() => PendingPro("Online")} class="expTopbar btn btn-outline-primary ">Online Products</button>
                                                                    <button type="button" onClick={() => PendingPro("OutofStock")} class="expTopbar btn btn-outline-primary ">Out of Stock</button>
                                                                    <button type="button" onClick={() => PendingPro("Inactive")} class="expTopbar btn btn-outline-primary  ">InActive</button>
                                                                    <button type="button" onClick={() => PendingPro("Suspended")} class="expTopbar btn btn-outline-primary ">Suspended</button>
                                                                    <button type="button" onClick={() => PendingPro("Incomplete")} class="expTopbar btn btn-outline-primary ">InComplete</button>

                                                                    <button type="button" onClick={() => PendingPro("Deleted")} class="expTopbar btn btn-outline-primary">Deleted</button>






                                                                </div>
                                                                <div className="card-body" style={{ paddingTop: 0 }}>

                                                                    <div className="card-body" style={{ padding: 5 }}>


                                                                        <div className="mt-4" style={{ maxWidth: '100%' }}>
                                                                            <MaterialTable
                                                                              data={PendingProducts}

                                                                                title=""
                                                                                columns={[

                                                                                    { title: 'Product', field: "image1", render: item => <img src={(item.image1)} alt="" border="3" height="80" width="80" /> },
                                                                                    { title: 'Product_Name', field: 'Product_Name' },
                                                                                    { title: 'Price', field: 'Price', type: 'numeric' },
                                                                                    { title: 'Special_Price', field: 'Special_Price', type: 'numeric' },
                                                                                    { title: 'Stock', field: 'Stock', },
                                                                                    { title: 'Created', field: 'Created', },

                                                                                    { title: 'Updated', field: 'Updated', },

                                                                                    // {

                                                                                    //     title: `${buttonshow ? 'Activate_Product' : ''}`
                                                                                    //     ,
                                                                                    //     render: rowData => <>
                                                                                    //         {buttonshow ? <BootstrapSwitchButton onChange={() => UpdateProStatus(rowData.Productid, rowData.Product_Status)} checked={false} size="md" /> : ""}
                                                                                    //     </>,
                                                                                    // }





                                                                                ]}

                                                                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                                                                options={{
                                                                                    rowStyle: rowData => ({
                                                                                        selectableRow: false,
                                                                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                                                    }),
                                                                                    actionsColumnIndex: -1,
                                                                                }}
                                                                                // other props
                                                                                actions={[
                                                                                    rowData => ({
                                                                                        icon: RemoveRedEye,
                                                                                        tooltip: 'View Seller Detail',
                                                                                        isFreeAction: true,
                                                                                        onClick: (event, rowData) => {
                                                                                            history.push({
                                                                                                pathname: '/NewProDetails',
                                                                                                state: { productid: rowData.Productid }

                                                                                            });


                                                                                        }


                                                                                    }),



                                                                                ]}

                                                                                options={{
                                                                                    maxBodyHeight: 400,
                                                                                    overflowX: 'x-scroll',
                                                                                    actionsColumnIndex: 7,
                                                                                    headerStyle: {
                                                                                        backgroundColor: '#222',
                                                                                        color: "white"
                                                                                      }
                                                                                  }}


                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
                    <SuperAdminFooter />
                </div>
            </div>
        </>
    )
}