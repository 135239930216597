import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Icon from '../../../../../../src/allPages/components/header/babylonIcon.png';
// import SFlogo from "../../../../../../src/allPages/pages/Admin/components/SFlogo.png"
import SFlogo from "../../../../../../src/allPages/pages/Admin/components/HUGO-04.png"
import profileLogo from '../../../../../../src/allPages/components/header/download (4).jpg'
import image_url from "../../../../../config/BaseUrl"
import Swal from 'sweetalert2'
import BaseUrl from "../../../../../config/BaseUrl"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../../config/Api/FakeuserApi";



export default function AdminHeader() {
    const history = useHistory();

    const [Loader, setLoader] = useState(false);
    const [Data, setData] = useState([]);
    const [active, setActive] = useState(false);




    const Token = localStorage.getItem('Token');
    const Role = localStorage.getItem('role');
    // const Profile = localStorage.getItem('Profile');

    const userData = JSON.parse(localStorage.getItem("user"));

    console.log("ROle", Role)
    console.log("user.Profile",Data.profile)

    useEffect(() => {

        if (localStorage.getItem("Token") == null) {
            history.push("/AdminLogin")
        }
        else {

            GetProfileData();
        }
    }, [])

    // GET PROFILE DATA API

    const GetProfileData = () => {
        setLoader(true);

        getApiWithToken(`${BaseUrl.Admin_Url}/superadminlogin`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                // console.log("datashow", data)
                if (data.status) {
                    console.log("api", data);
                    setData(data.data);
                    // localStorage.setItem('user', JSON.stringify(data.data));
                } else {
                    if (data.message == "tokenexpire") {
                        history.push("/AdminLogin")
                        console.log("admin condition")
                    }

                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }

    const logout = () => {
        localStorage.clear();
    }

    const OpenDropdown = () => {
        if (active == false) {
            setActive(true);
        } else {
            setActive(false)
        }

    }



    let removeClass = () => {


        console.log("running")

        var x = document.getElementsByTagName("BODY")[0].classList;

        if (x.contains("sidebar-gone")) {

            x.remove('sidebar-gone')
            x.add('sidebar-show')

        }
        else {

            if (x.contains("sidebar-mini")) {

                x.remove('sidebar-mini')
            }


            else {

                x.add('sidebar-mini')

            }


        }


    }


    return (
        <>
            <div className="navbar-bg" />
            <nav className="navbar navbar-expand-lg main-navbar sticky">
                <div className="form-inline mr-auto">
                    <ul class="navbar-nav mr-3">
                        <li><a href="#" class="nav-link nav-link-lg collapse-btn"> <i onClick={() => removeClass()} className="fa fa-bars" style={{ color: "grey" }}></i></a></li>
                        <li>

                        </li>
                    </ul>
                </div>

                <ul className="navbar-nav navbar-right">
                    <li><a href="#" className="nav-link nav-link-lg fas fa-expand fullscreen-btn" title="Full Screen" style={{ color: "grey", fontSize: "23px", marginRight: "5px", paddingTop: "28px" }}>
                        <i data-feather="maximize" />
                    </a></li>
                    <li className="dropdown dropdown-list-toggle"><a href="#" data-toggle="dropdown" className=" nav-link-lg far fa-envelope-open" title="Messages" style={{ color: "grey", fontSize: "23px", marginTop: "30px" }}><i data-feather="mail" className="mailAnim" />
                        <span className="badge headerBadge1">
                        </span> </a>
                        <div className="dropdown-menu dropdown-list dropdown-menu-right pullDown">
                            <div className="dropdown-header">
                                Messages
                                <div className="float-right">
                                    <a href="#">Mark All As Read</a>
                                </div>
                            </div>
                            <div className="dropdown-list-content dropdown-list-message">
                                <a href="#" className="dropdown-item"> <span className="dropdown-item-avatar
											text-white"> <img alt="image" src="assets/img/users/user-1.png" className="rounded-circle" />
                                </span> <span className="dropdown-item-desc"> <span className="message-user">John
                                    Deo</span>
                                        <span className="time messege-text">Please check your mail !!</span>
                                        <span className="time">2 Min Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-avatar text-white">
                                    <img alt="image" src="assets/img/users/user-2.png" className="rounded-circle" />
                                </span> <span className="dropdown-item-desc"> <span className="message-user">Sarah
                                    Smith</span> <span className="time messege-text">Request for leave
                                        application</span>
                                        <span className="time">5 Min Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-avatar text-white">
                                    <img alt="image" src="assets/img/users/user-5.png" className="rounded-circle" />
                                </span> <span className="dropdown-item-desc"> <span className="message-user">Jacob
                                    Ryan</span> <span className="time messege-text">Your payment invoice is
                                        generated.</span> <span className="time">12 Min Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-avatar text-white">
                                    <img alt="image" src="assets/img/users/user-4.png" className="rounded-circle" />
                                </span> <span className="dropdown-item-desc"> <span className="message-user">Lina
                                    Smith</span> <span className="time messege-text">hii John, I have upload
                                        doc
                                        related to task.</span> <span className="time">30
                                            Min Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-avatar text-white">
                                    <img alt="image" src="assets/img/users/user-3.png" className="rounded-circle" />
                                </span> <span className="dropdown-item-desc"> <span className="message-user">Jalpa
                                    Joshi</span> <span className="time messege-text">Please do as specify.
                                        Let me
                                        know if you have any query.</span> <span className="time">1
                                            Days Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-avatar text-white">
                                    <img alt="image" src="assets/img/users/user-2.png" className="rounded-circle" />
                                </span> <span className="dropdown-item-desc"> <span className="message-user">Sarah
                                    Smith</span> <span className="time messege-text">Client Requirements</span>
                                        <span className="time">2 Days Ago</span>
                                    </span>
                                </a>
                            </div>
                            <div className="dropdown-footer text-center">
                                <a href="#">View All <i className="fas fa-chevron-right" /></a>
                            </div>
                        </div>
                    </li>
                    <li className="dropdown dropdown-list-toggle"><a href="#" data-toggle="dropdown" className="notification-toggle far fa-bell" title="Notifications" style={{ color: "grey", fontSize: "23px", marginTop: "30px" }}><i data-feather="bell" />
                    </a>
                        <div className="dropdown-menu dropdown-list dropdown-menu-right pullDown">
                            <div className="dropdown-header">
                                Notifications
                                <div className="float-right">
                                    <a href="#">Mark All As Read</a>
                                </div>
                            </div>
                            <div className="dropdown-list-content dropdown-list-icons">
                                <a href="#" className="dropdown-item dropdown-item-unread"> <span className="dropdown-item-icon l-bg-orange text-white"> <i className="far fa-envelope" />
                                </span> <span className="dropdown-item-desc"> You got new mail, please check. <span className="time">2 Min
                                    Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-icon l-bg-purple text-white"> <i className="fas fa-bell" />
                                </span> <span className="dropdown-item-desc"> Meeting with <b>John Deo</b> and <b>Sarah Smith </b> at
                                    10:30 am <span className="time">10 Hours
                                        Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-icon l-bg-green text-white"> <i className="far fa-check-circle" />
                                </span> <span className="dropdown-item-desc"> Sidebar Bug is fixed by Kevin <span className="time">12
                                    Hours
                                    Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-icon bg-danger text-white"> <i className="fas fa-exclamation-triangle" />
                                </span> <span className="dropdown-item-desc"> Low disk space error!!!. <span className="time">17 Hours
                                    Ago</span>
                                    </span>
                                </a> <a href="#" className="dropdown-item"> <span className="dropdown-item-icon bg-info text-white"> <i className="fas
												fa-bell" />
                                </span> <span className="dropdown-item-desc"> Welcome to Gati
                                    template! <span className="time">Yesterday</span>
                                    </span>
                                </a>
                            </div>
                            <div className="dropdown-footer text-center">
                                <a href="#">View All <i className="fas fa-chevron-right" /></a>
                            </div>
                        </div>
                    </li>
                    <li className="dropdown"><a href="#" data-toggle="dropdown" className="nav-link dropdown-toggle  nav-link-user"> <img alt="image" src={(Data?.profile)} className="user-img-radious-style" style={{ marginTop: "20px" }} /> <span className="d-sm-none d-lg-inline-block" /></a>
                        <div className="dropdown-menu dropdown-menu-right pullDown">
                            <div className="dropdown-title">Hello {userData?.fname + " " + userData?.lname}</div>
                            <NavLink to="/AdminProfile" className="dropdown-item has-icon"> <i className="far
										fa-user" /> Profile
                            </NavLink>

                            <div className="dropdown-divider" />
                            <NavLink to="/AdminLogin" onClick={() => localStorage.clear()} className="dropdown-item has-icon text-danger"> <i className="fas fa-sign-out-alt" />
                                Logout
                            </NavLink>
                        </div>
                    </li>
                </ul>
            </nav>
            <div className="main-sidebar sidebar-style-2">
                <aside id="sidebar-wrapper">
                    <div className="sidebar-brand">
                        <a href="#"><img alt="image" src={'assets/louralogo/Loura-09.png'} style={{ marginRight: 4 }} className="header-logo" /><span className="logo-name"> </span>
                        </a>
                    </div>
                    {/* SIDBAR */}
                    <div className="sidebar-user" >
                        <div className="sidebar-user-picture">
                            <img alt="image" src={(Data?.profile)} />
                        </div>
                        <div className="sidebar-user-details">
                            <div className="user-name">{userData?.fname + " " + userData?.lname}</div>
                            <div className="user-role">Admin</div>
                            <div className="sidebar-userpic-btn">
                                <a href="" data-toggle="tooltip" title="Profile">
                                    <i data-feather="user" />
                                </a>
                                <a href="" data-toggle="tooltip" title="Mail">
                                    <i data-feather="mail" />
                                </a>
                                <a href="" data-toggle="tooltip" title="Chat">
                                    <i data-feather="message-square" />
                                </a>
                                <a href="" data-toggle="tooltip" title="Log Out">
                                    <i data-feather="log-out" />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* //conditions */}






                    {Role == "superadmin" ?
                        <ul className="sidebar-menu" style={{ overflowY: "scroll", height: "500px" }}>



                            <li className="">
                                <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
                                    <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
                                </NavLink>
                            </li>

                            <li className="dropdown">
                                <a onClick={() => OpenDropdown()} className="menu-toggle nav-link has-dropdown"><i
                                    data-feather="briefcase" className="fas fa-bullhorn"></i><span>AFFILATE MARKETING</span></a>

                                <ul style={active ? { display: "block" } : { display: "none" }} className="dropdown-menu">
                                    <li><Link className="nav-link" to="/ActiveAffilate">ACTIVE</Link></li>
                                    <li><Link className="nav-link" to="/PendingAffilate">PENDING</Link></li>
                                </ul>

                            </li>
                            <li className="">
                                <NavLink to="/SubadminView" activeClassName="active" className="nav-link">
                                    <i className="fas fa-users-cog"></i><span>SUB ADMIN</span>
                                </NavLink>

                            </li>
                            <li className="">
                                <NavLink to="/ViewRoles" activeClassName="active" className="nav-link"><i className="fas fa-tasks"></i><span>ROLES</span></NavLink>

                            </li>
                            <li className="">
                                <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink>
                                <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink>

                                <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink>
                                <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink>
                                <NavLink to="/AdminOrder" activeClassName="active" className="nav-link"><i class="fas fa-sort-amount-up"></i><span>ORDERS</span></NavLink>


                                <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink>
                                <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink>
                                <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink>

                                <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
                                <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
                                <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink>



                                <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

                            </li>
                            <li className="">
                                <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
                            </li>

                        </ul>
                        : (Role == "Category" ?
                            <ul className="sidebar-menu" style={{ overflowY: "scroll", height: "500px" }}>



                                <li className="">
                                    <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
                                        <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
                                    </NavLink>
                                </li>

                                {/* <li className="dropdown">
<a  onClick={() => OpenDropdown()} className="menu-toggle nav-link has-dropdown"><i
data-feather="briefcase" className="fas fa-bullhorn"></i><span>AFFILATE MARKETING</span></a>

<ul style={active ? { display: "block" } : { display: "none" }} className="dropdown-menu">
<li><Link  className="nav-link" to="/ActiveAffilate">ACTIVE</Link></li>
<li><Link  className="nav-link" to="/PendingAffilate">PENDING</Link></li>
</ul>

</li> */}


                                <li className="">
                                    <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink>
                                    {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink>

<NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink>
<NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink>

<NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink>
<NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink>
<NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink>

<NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
<NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
<NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink>

*/}

                                    <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

                                </li>
                                <li className="">
                                    <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
                                </li>

                            </ul>
                            : (Role == "Email List" ?
                                <ul className="sidebar-menu" style={{ overflowY: "scroll", height: "500px" }}>



                                    <li className="">
                                        <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
                                            <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
                                        </NavLink>
                                    </li>




                                    <li className="">


                                        <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
                                        <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
                                        <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink>

                                    </li>
                                    <li className="">
                                        <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
                                    </li>

                                </ul>
                                : (Role == "Send Email List" ?
                                    <ul className="sidebar-menu" style={{ overflowY: "scroll", height: "500px" }}>



                                        <li className="">
                                            <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
                                                <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
                                            </NavLink>
                                        </li>



                                        <li className="">
                                            {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
                                            {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink> */}

                                            {/* <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink> */}
                                            {/* <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink> */}

                                            {/* <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink> */}
                                            {/* <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink> */}
                                            {/* <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink> */}

                                            <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
                                            <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
                                            <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink>



                                            {/* <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink> */}

                                        </li>
                                        <li className="">
                                            <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
                                        </li>

                                    </ul>
                                    : (Role == "Email Marketing" ?
                                        <ul className="sidebar-menu" style={{ overflowY: "scroll", height: "500px" }}>



                                            <li className="">
                                                <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
                                                    <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
                                                </NavLink>
                                            </li>


                                            <li className="">
                                                <NavLink to="/ViewRoles" activeClassName="active" className="nav-link"><i className="fas fa-tasks"></i><span>ROLES</span></NavLink>

                                            </li>
                                            <li className="">
                                                {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
                                                {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink> */}

                                                {/* <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink> */}
                                                {/* <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink> */}

                                                {/* <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink> */}
                                                {/* <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink> */}
                                                {/* <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink> */}

                                                <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
                                                <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
                                                <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink>



                                                {/* <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink> */}

                                            </li>
                                            <li className="">
                                                <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
                                            </li>

                                        </ul>
                                        : (Role == "Send SMS List" ?
                                            <ul className="sidebar-menu" style={{ overflowY: "scroll", height: "500px" }}>



                                                <li className="">
                                                    <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
                                                        <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
                                                    </NavLink>
                                                </li>

                                                {/* <li className="dropdown">
<a  onClick={() => OpenDropdown()} className="menu-toggle nav-link has-dropdown"><i
data-feather="briefcase" className="fas fa-bullhorn"></i><span>AFFILATE MARKETING</span></a>

<ul style={active ? { display: "block" } : { display: "none" }} className="dropdown-menu">
<li><Link  className="nav-link" to="/ActiveAffilate">ACTIVE</Link></li>
<li><Link  className="nav-link" to="/PendingAffilate">PENDING</Link></li>
</ul>

</li> */}


                                                <li className="">
                                                    {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
                                                    {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink> */}

                                                    {/* <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink> */}
                                                    {/* <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink> */}

                                                    {/* <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink> */}
                                                    <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink>
                                                    <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink>

                                                    {/* <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink> */}
                                                    {/* <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink> */}
                                                    {/* <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink> */}



                                                    {/* <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink> */}

                                                </li>
                                                <li className="">
                                                    <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
                                                </li>

                                            </ul>
                                            : (Role == "SMS Marketing" ?
                                                <ul className="sidebar-menu" style={{ overflowY: "scroll", height: "500px" }}>



                                                    <li className="">
                                                        <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
                                                            <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
                                                        </NavLink>
                                                    </li>

                                                    {/* <li className="dropdown">
<a  onClick={() => OpenDropdown()} className="menu-toggle nav-link has-dropdown"><i
data-feather="briefcase" className="fas fa-bullhorn"></i><span>AFFILATE MARKETING</span></a>

<ul style={active ? { display: "block" } : { display: "none" }} className="dropdown-menu">
<li><Link  className="nav-link" to="/ActiveAffilate">ACTIVE</Link></li>
<li><Link  className="nav-link" to="/PendingAffilate">PENDING</Link></li>
</ul>

</li> */}


                                                    {/* <li className="">
<NavLink to="/ViewRoles" activeClassName="active" className="nav-link"><i className="fas fa-tasks"></i><span>ROLES</span></NavLink>

</li> */}

                                                    <li className="">
                                                        {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
                                                        {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink> */}

                                                        {/* <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink> */}
                                                        {/* <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink> */}

                                                        {/* <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink> */}
                                                        <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink>
                                                        <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink>

                                                        {/* <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink> */}
                                                        {/* <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink> */}
                                                        {/* <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink> */}



                                                        <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

                                                    </li>
                                                    <li className="">
                                                        <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
                                                    </li>

                                                </ul>
                                                : (Role == "Brand Names" ?
                                                    <ul className="sidebar-menu" style={{ overflowY: "scroll", height: "500px" }}>



                                                        <li className="">
                                                            <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
                                                                <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
                                                            </NavLink>
                                                        </li>




                                                        <li className="">
                                                            {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
                                                            {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink> */}

                                                            {/* <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink> */}
                                                            {/* <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink> */}

                                                            <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink>
                                                            {/* <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink> */}
                                                            {/* <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink> */}

                                                            {/* <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink> */}
                                                            {/* <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
<NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink> */}



                                                            <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

                                                        </li>
                                                        <li className="">
                                                            <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
                                                        </li>

                                                    </ul>
                                                    : (Role == "Pending Vendors" ?
                                                        <ul className="sidebar-menu" style={{ overflowY: "scroll", height: "500px" }}>



                                                            <li className="">
                                                                <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
                                                                    <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
                                                                </NavLink>
                                                            </li>




                                                            <li className="">
                                                                {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
                                                                {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink> */}

                                                                <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink>
                                                                <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink>

                                                                {/* <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink>
<NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink>
<NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink> */}
                                                                {/*
<NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
<NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
<NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink> */}



                                                                <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

                                                            </li>
                                                            <li className="">
                                                                <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
                                                            </li>

                                                        </ul>
                                                        : (Role == "Active Vendors" ?
                                                            <ul className="sidebar-menu" style={{ overflowY: "scroll", height: "500px" }}>



                                                                <li className="">
                                                                    <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
                                                                        <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
                                                                    </NavLink>
                                                                </li>




                                                                <li className="">
                                                                    {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
                                                                    {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink> */}

                                                                    <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink>
                                                                    <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink>

                                                                    {/* <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink> */}
                                                                    {/* <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink> */}
                                                                    {/* <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink> */}

                                                                    {/* <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
<NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
<NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink> */}



                                                                    <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

                                                                </li>
                                                                <li className="">
                                                                    <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
                                                                </li>

                                                            </ul>
                                                            : (Role == "Inventory Management" ?
                                                                <ul className="sidebar-menu" style={{ overflowY: "scroll", height: "500px" }}>



                                                                    <li className="">
                                                                        <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
                                                                            <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
                                                                        </NavLink>
                                                                    </li>



                                                                    <li className="">
                                                                        {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
                                                                        <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink>

                                                                        {/* <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink>
<NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink>

<NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink>
<NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink>
<NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink>

<NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
<NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
<NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink>
*/}


                                                                        <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

                                                                    </li>
                                                                    <li className="">
                                                                        <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
                                                                    </li>

                                                                </ul>


                                                                : (Role == "Affilate Marketing" ?

                                                                <ul className="sidebar-menu" style={{ overflowY: "scroll", height: "500px" }}>



                                                                <li className="">
                                                                    <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
                                                                        <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
                                                                    </NavLink>
                                                                </li>

                                                                <li className="dropdown">
                                <a onClick={() => OpenDropdown()} className="menu-toggle nav-link has-dropdown"><i
                                    data-feather="briefcase" className="fas fa-bullhorn"></i><span>AFFILATE MARKETING</span></a>

                                <ul style={active ? { display: "block" } : { display: "none" }} className="dropdown-menu">
                                    <li><Link className="nav-link" to="/ActiveAffilate">ACTIVE</Link></li>
                                    <li><Link className="nav-link" to="/PendingAffilate">PENDING</Link></li>
                                </ul>

                            </li>




                                                                <li className="">



                                                                    <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

                                                                </li>
                                                                <li className="">
                                                                    <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
                                                                </li>

                                                            </ul>:""


                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    }
                </aside>
            </div>

        </>
    )

}































// {
//     userData.role=="superadmin" ?

//     <ul className="sidebar-menu" style={{overflowY:"scroll",height:"500px"}}>



//         <li className="">
//             <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
//                 <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
//             </NavLink>
//         </li>

//         <li className="dropdown">
//             <a  onClick={() => OpenDropdown()} className="menu-toggle nav-link has-dropdown"><i
//                 data-feather="briefcase" className="fas fa-bullhorn"></i><span>AFFILATE MARKETING</span></a>

//             <ul style={active ? { display: "block" } : { display: "none" }} className="dropdown-menu">
//                 <li><Link  className="nav-link" to="/ActiveAffilate">ACTIVE</Link></li>
//                 <li><Link  className="nav-link" to="/PendingAffilate">PENDING</Link></li>
//             </ul>

//         </li>
//         <li className="">
//             <NavLink to="/SubadminView" activeClassName="active" className="nav-link">
//                 <i className="fas fa-users-cog"></i><span>SUB ADMIN</span>
//             </NavLink>

//         </li>
//         <li className="">
//             <NavLink to="/ViewRoles" activeClassName="active" className="nav-link"><i className="fas fa-tasks"></i><span>ROLES</span></NavLink>

//         </li>
//         <li className="">
//             <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink>
//             <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink>

//             <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink>
//             <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink>

//             <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink>
//             <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink>
//             <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink>

//             <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
//             <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
//             <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink>



//             <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

//         </li>
//         <li className="">
//             <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
//         </li>

//     </ul>

//     : userData.role == "Category" ?

// <ul className="sidebar-menu" style={{overflowY:"scroll",height:"500px"}}>



// <li className="">
// <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
// <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
// </NavLink>
// </li>

// {/* <li className="dropdown">
// <a  onClick={() => OpenDropdown()} className="menu-toggle nav-link has-dropdown"><i
// data-feather="briefcase" className="fas fa-bullhorn"></i><span>AFFILATE MARKETING</span></a>

// <ul style={active ? { display: "block" } : { display: "none" }} className="dropdown-menu">
// <li><Link  className="nav-link" to="/ActiveAffilate">ACTIVE</Link></li>
// <li><Link  className="nav-link" to="/PendingAffilate">PENDING</Link></li>
// </ul>

// </li> */}


// <li className="">
// <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink>
// {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink>

// <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink>
// <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink>

// <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink>
// <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink>
// <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink>

// <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
// <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
// <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink>

// */}

// <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

// </li>
// <li className="">
// <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
// </li>

// </ul>:userData.role == "Email List" ?

// <ul className="sidebar-menu" style={{overflowY:"scroll",height:"500px"}}>



// <li className="">
//     <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
//         <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
//     </NavLink>
// </li>




// <li className="">


//     <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
//     <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
//     <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink>

// </li>
// <li className="">
//     <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
// </li>

// </ul>:userData.role == "Send Email List" ?

// <ul className="sidebar-menu" style={{overflowY:"scroll",height:"500px"}}>



// <li className="">
// <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
//     <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
// </NavLink>
// </li>



// <li className="">
// {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
// {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink> */}

// {/* <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink> */}
// {/* <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink> */}

// {/* <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink> */}
// {/* <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink> */}
// {/* <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink> */}

// <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
// <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
// <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink>



// {/* <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink> */}

// </li>
// <li className="">
// <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
// </li>

// </ul>:userData.role == "Email Marketing" ?

// <ul className="sidebar-menu" style={{overflowY:"scroll",height:"500px"}}>



// <li className="">
// <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
// <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
// </NavLink>
// </li>


// <li className="">
// <NavLink to="/ViewRoles" activeClassName="active" className="nav-link"><i className="fas fa-tasks"></i><span>ROLES</span></NavLink>

// </li>
// <li className="">
// {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
// {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink> */}

// {/* <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink> */}
// {/* <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink> */}

// {/* <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink> */}
// {/* <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink> */}
// {/* <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink> */}

// <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
// <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
// <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink>



// {/* <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink> */}

// </li>
// <li className="">
// <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
// </li>

// </ul>:userData.role == "Send SMS List" ?

// <ul className="sidebar-menu" style={{overflowY:"scroll",height:"500px"}}>



// <li className="">
// <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
// <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
// </NavLink>
// </li>

// {/* <li className="dropdown">
// <a  onClick={() => OpenDropdown()} className="menu-toggle nav-link has-dropdown"><i
// data-feather="briefcase" className="fas fa-bullhorn"></i><span>AFFILATE MARKETING</span></a>

// <ul style={active ? { display: "block" } : { display: "none" }} className="dropdown-menu">
// <li><Link  className="nav-link" to="/ActiveAffilate">ACTIVE</Link></li>
// <li><Link  className="nav-link" to="/PendingAffilate">PENDING</Link></li>
// </ul>

// </li> */}


// <li className="">
// {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
// {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink> */}

// {/* <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink> */}
// {/* <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink> */}

// {/* <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink> */}
// <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink>
// <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink>

// {/* <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink> */}
// {/* <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink> */}
// {/* <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink> */}



// {/* <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink> */}

// </li>
// <li className="">
// <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
// </li>

// </ul>:userData.role == "SMS Marketing" ?

// <ul className="sidebar-menu" style={{overflowY:"scroll",height:"500px"}}>



// <li className="">
// <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
// <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
// </NavLink>
// </li>

// {/* <li className="dropdown">
// <a  onClick={() => OpenDropdown()} className="menu-toggle nav-link has-dropdown"><i
// data-feather="briefcase" className="fas fa-bullhorn"></i><span>AFFILATE MARKETING</span></a>

// <ul style={active ? { display: "block" } : { display: "none" }} className="dropdown-menu">
// <li><Link  className="nav-link" to="/ActiveAffilate">ACTIVE</Link></li>
// <li><Link  className="nav-link" to="/PendingAffilate">PENDING</Link></li>
// </ul>

// </li> */}


// {/* <li className="">
// <NavLink to="/ViewRoles" activeClassName="active" className="nav-link"><i className="fas fa-tasks"></i><span>ROLES</span></NavLink>

// </li> */}

// <li className="">
// {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
// {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink> */}

// {/* <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink> */}
// {/* <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink> */}

// {/* <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink> */}
// <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink>
// <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink>

// {/* <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink> */}
// {/* <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink> */}
// {/* <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink> */}



// <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

// </li>
// <li className="">
// <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
// </li>

// </ul>:userData.role == "Brand Names" ?

// <ul className="sidebar-menu" style={{overflowY:"scroll",height:"500px"}}>



// <li className="">
// <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
// <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
// </NavLink>
// </li>




// <li className="">
// {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
// {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink> */}

// {/* <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink> */}
// {/* <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink> */}

// <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink>
// {/* <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink> */}
// {/* <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink> */}

// {/* <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink> */}
// {/* <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
// <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink> */}



// <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

// </li>
// <li className="">
// <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
// </li>

// </ul>:userData.role == "Pending Vendors" ?

// <ul className="sidebar-menu" style={{overflowY:"scroll",height:"500px"}}>



// <li className="">
// <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
// <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
// </NavLink>
// </li>




// <li className="">
// {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
// {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink> */}

// <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink>
// <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink>

// {/* <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink>
// <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink>
// <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink> */}
// {/*
// <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
// <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
// <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink> */}



// <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

// </li>
// <li className="">
// <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
// </li>

// </ul>:userData.role == "Active Vendors" ?

// <ul className="sidebar-menu" style={{overflowY:"scroll",height:"500px"}}>



// <li className="">
// <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
// <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
// </NavLink>
// </li>




// <li className="">
// {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
// {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink> */}

// <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink>
// <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink>

// {/* <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink> */}
// {/* <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink> */}
// {/* <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink> */}

// {/* <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
// <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
// <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink> */}



// <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

// </li>
// <li className="">
// <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
// </li>

// </ul>:userData.role == "Inventory Management" ?

// <ul className="sidebar-menu" style={{overflowY:"scroll",height:"500px"}}>



// <li className="">
// <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
// <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
// </NavLink>
// </li>



// <li className="">
// {/* <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink> */}
// <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink>

// {/* <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink>
// <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink>

// <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink>
// <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink>
// <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink>

// <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
// <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
// <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink>
// */}


// <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

// </li>
// <li className="">
// <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
// </li>

// </ul>:userData.role == " Category" ?

// <ul className="sidebar-menu" style={{overflowY:"scroll",height:"500px"}}>



// <li className="">
// <NavLink to="/adminDashboard" activeClassName="active" className="nav-link">
// <i className="fas fa-tachometer-alt"></i><span>DASHBOARD</span>
// </NavLink>
// </li>

// {/* <li className="dropdown">
// <a  onClick={() => OpenDropdown()} className="menu-toggle nav-link has-dropdown"><i
// data-feather="briefcase" className="fas fa-bullhorn"></i><span>AFFILATE MARKETING</span></a>

// <ul style={active ? { display: "block" } : { display: "none" }} className="dropdown-menu">
// <li><Link  className="nav-link" to="/ActiveAffilate">ACTIVE</Link></li>
// <li><Link  className="nav-link" to="/PendingAffilate">PENDING</Link></li>
// </ul>

// </li> */}


// <li className="">
// <NavLink to="/Category" activeClassName="active" className="nav-link"><i className="fa fa-list-alt"></i><span>CATEGORY</span></NavLink>
// {/* <NavLink to="/InventoryManagement" activeClassName="active" className="nav-link"><i className="fas fa-dolly-flatbed"></i><span>INVENTORY MANAGEMENT </span></NavLink>

// <NavLink to="/ActiveVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-on"></i><span>ACTIVE VENDORS</span></NavLink>
// <NavLink to="/PendingVendors" activeClassName="active" className="nav-link"><i className="	fas fa-toggle-off"></i><span>PENDING VENDORS</span></NavLink>

// <NavLink to="/BrandNames" activeClassName="active" className="nav-link"><i className="fab fa-product-hunt"></i><span>BRAND NAMES</span></NavLink>
// <NavLink to="/SmsMarketing" activeClassName="active" className="nav-link"><i className="fas fa-sms"></i><span>SMS MARKETING</span></NavLink>
// <NavLink to="/SendSmsList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND SMS LIST </span></NavLink>

// <NavLink to="/EmailMarketing" activeClassName="active" className="nav-link"><i className="fa fa-envelope"></i><span>EMAIL MARKETING </span></NavLink>
// <NavLink to="/SendEmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span>SEND EMAIL LIST </span></NavLink>
// <NavLink to="/EmailList" activeClassName="active" className="nav-link"><i className="fas fa-reply"></i><span> EMAIL LIST </span></NavLink>

// */}

// <NavLink to="/AdminProfile" activeClassName="active" className="nav-link"><i className="fas fa-user"></i><span>PROFILE</span></NavLink>

// </li>
// <li className="">
// <NavLink to="/AdminLogin" onClick={() => logout()} className="nav-link"><i className="fas fa-sign-out-alt"></i><span>LOGOUT</span></NavLink>
// </li>

// </ul>:"safdar"





//     }
