import React, { useState, useEffect } from "react";
import AdminHeader from "../components/adminheader/AdminHeader"
import AdminFooter from "../components/adminfooter/AdminFooter"
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import MaterialTable from 'material-table'
import image_url from "../../../../config/BaseUrl"
import { Link, NavLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import { RemoveRedEye } from "@material-ui/icons"
import { color } from "@mui/system";
import Button from 'react-bootstrap/Button'
import { Modal } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));



function myfunc() {
    // var print=document.getElementById("print")

    // print.style.display = "none";
    // window.print();
    // print.style.display = "inline-block";



    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head><title>' + "Invoice" + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1 style="text-align:center">' + "Invoice" + '</h1>');
    mywindow.document.write(document.getElementById("elem").innerHTML);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.print();

    // mywindow.focus(); // necessary for IE >= 10*/

    // mywindow.close();

    return true;

}



export default function Orders(props) {

    const [GetOrders, SetGetOrders] = useState([])
    const [Loader, setLoader] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null);
    const [GetAllAIvendors, SetGetAIllAvendors] = useState([])
    const [Active, SetActive] = useState(true);
    const [Accept, SetAccept] = useState(false);
    const [packed, Setpacked] = useState(false);
    const [ontheway, Setontheway] = useState(false);
    const [arrived, Setarrived] = useState(false);
    const [buttonshow, Setbuttonshow] = useState(false);
    const [buttoninv, Setbuttoninv] = useState(false);
    const [invoice, Setinvoice] = useState(false);
    // Second Modal
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);

    const handleShow2 = () =>{

        setShow2(true);

    }

    const [Getallinvoicedata, setGetallinvoicedata] = useState([]);








    const Token = localStorage.getItem('Token');

    useEffect(() => {
        ViewPendingProducts("Pending")
    }, [])



    // View ALL pending Products


    const ViewPendingProducts = (status) => {

        if (status == "Pending") {
            SetAccept(true)
            Setpacked(false)
            Setontheway(false)
            Setarrived(false)
            Setbuttonshow(true)
            Setbuttoninv(false)
            Setinvoice(false)
            // SetActive(false)
        }
        if (status == "Accept") {
            SetAccept(false)
            Setpacked(true)
            Setontheway(false)
            Setarrived(false)
            SetActive(false)
            Setbuttonshow(true)
            Setbuttoninv(true)
            Setinvoice(true)



        }
        if (status == "Packed") {
            SetAccept(false)
            Setpacked(false)
            Setontheway(true)
            Setarrived(false)
            SetActive(false)
            Setbuttonshow(true)
            Setbuttoninv(true)
            Setinvoice(true)



        }
        if (status == "Ontheway") {
            SetAccept(false)
            Setpacked(false)
            Setontheway(false)
            Setarrived(true)
            Setbuttonshow(true)
            SetActive(false)
            Setbuttoninv(true)
            Setinvoice(true)


        }

        if (status == "Arrived") {
            SetAccept(false)
            Setpacked(false)
            Setontheway(false)
            Setarrived(false)
            SetActive(false)
            Setbuttonshow(false)
            Setbuttoninv(true)
            Setinvoice(true)



        }
        if (status == "Recieved") {
            SetAccept(false)
            Setpacked(false)
            Setontheway(false)
            Setarrived(false)
            SetActive(false)
            Setbuttonshow(false)
            Setbuttoninv(true)
            Setinvoice(true)



        }

        if (status == "Cancel") {
            SetAccept(false)
            Setpacked(false)
            Setontheway(false)
            Setarrived(false)
            SetActive(false)
            Setbuttonshow(false)
            Setbuttoninv(true)
            Setinvoice(true)



        }




        setLoader(true);

        getApiWithToken(`${BaseUrl.Admin_Url}/GetOrder?OrderStatus=${status}`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    SetGetOrders(data.data)
                    console.log("PENDING PRODCT admin==>", data.data)
                    // setdisable(true);
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }


    // UPDATE STATUS PUT API

    const UpdateStatus = (status, id) => {
        setLoader(true);
        var formdata = new FormData();

        formdata.append("OrderStatus", status);
        formdata.append("Orderid", id);


        PutApi(`${BaseUrl.Admin_Url}/GetOrder`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("ADMINdata==>", data)
                    ViewPendingProducts("Pending")
                    // handleClose2()
                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    }


    //invice api

    const ViewInvoiceData = (OrderNo) => {




        setLoader(true);

        getApiWithToken(`${BaseUrl.Admin_Url}/GenerateInvoice?OrderNo=${OrderNo}`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    setGetallinvoicedata(data.data)
                    handleShow2()
                    console.log("invoive==>", data)
                    // setdisable(true);
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }





    return (
        <>
            {Loader ? <div className="loader" /> : null}
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <AdminHeader />
                    <div className="main-content">
                        <section className="section">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h4>Orders Overview</h4>
                                        </div>
                                        <div className="card-body">
                                            <section className="section">
                                                <div className="section-body">
                                                    <div className="row clearfix">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <div className="card">

                                                                <div className="card-body" style={{ paddingTop: 0 }}>
                                                                    <div className="card-body">
                                                                        <button type="button" onClick={() => ViewPendingProducts("Pending")} class={Active ? " expTopbar btn btn-primary active" : "expTopbar btn btn-outline-primary"}>Pending</button>
                                                                        <button type="button" onClick={() => ViewPendingProducts("Accept")} class="expTopbar btn btn-outline-primary "  >Accept</button>
                                                                        <button type="button" onClick={() => ViewPendingProducts("Packed")} class="expTopbar btn btn-outline-primary">Packing</button>
                                                                        <button type="button" onClick={() => ViewPendingProducts("Ontheway")} class="expTopbar btn btn-outline-primary">On the Way</button>
                                                                        <button type="button" onClick={() => ViewPendingProducts("Arrived")} class="expTopbar btn btn-outline-primary">Arrived</button>
                                                                        <button type="button" onClick={() => ViewPendingProducts("Recieved")} class="expTopbar btn btn-outline-primary">Received</button>
                                                                        <button type="button" onClick={() => ViewPendingProducts("Cancel")} class="expTopbar btn btn-outline-primary">Cancel</button>
                                                                        {/* <button type="button" onClick={() => ViewAllProducts("Incomplete")} class="expTopbar btn btn-outline-primary">InComplete</button> */}
                                                                        {/* <button type="button" onClick={() => UpdateStatus()} class="expTopbar btn btn-outline-primary">Deleted</button> */}


                                                                    </div>


                                                                    <div className="card-body" style={{ padding: 5 }}>
                                                                        <div className="row" style={{ marginLeft: 10 }}>


                                                                        </div>

                                                                        <div className="card-body">
                                                                            <MaterialTable
                                                                                data={GetOrders}
                                                                                title=" "
                                                                                columns={[
                                                                                    // { title: 'Product', field: "", render: item => <img src={image_url.image_url(item.image1)} alt="" border="3" height="80" width="80" /> },
                                                                                    { title: 'Product_Name', field: 'Product_Name' },
                                                                                    { title: 'Order_No', field: 'OrderNo' },
                                                                                    { title: 'Send_To', field: 'UserName' },
                                                                                    // { title: 'Packed', field: '' },
                                                                                    { title: 'Order_Status', field: 'OrderStatus' },
                                                                                    { title: 'Payment_Method', field: 'PaymentMethod' },
                                                                                    { title: 'Quantity', field: 'Quantity', },
                                                                                    { title: 'Created', field: 'Created', },
                                                                                    { title: 'Address', field: 'Address' },
                                                                                    { title: 'State', field: 'State' },

                                                                                    {
                                                                                        title: `${buttoninv ? 'Invoice_Generate' : ""}`,

                                                                                        render: rowData => <>
                                                                                            {invoice ? <Button onClick={() => ViewInvoiceData(rowData.OrderNo)} variant="primary">Invoice</Button> : ""}


                                                                                        </>
                                                                                    },



                                                                                ]}

                                                                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                                                                options={{
                                                                                    rowStyle: rowData => ({
                                                                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                                                    }),
                                                                                    actionsColumnIndex: -1,
                                                                                }}
                                                                                // other props
                                                                                actions={[
                                                                                    rowData => ({
                                                                                        icon: RemoveRedEye,
                                                                                        tooltip: 'View Order Detail',
                                                                                        isFreeAction: true,
                                                                                        onClick: (event, rowData) => {
                                                                                            props.history.push({
                                                                                                pathname: '/AdminOdetails',
                                                                                                state: rowData.OrderNo

                                                                                            });

                                                                                        }


                                                                                    }),



                                                                                ]}


                                                                                options={{
                                                                                    // maxBodyHeight: 400,
                                                                                    // overflowX: 'x-scroll',
                                                                                    actionsColumnIndex: 11,
                                                                                    headerStyle: {
                                                                                        backgroundColor: '#222',
                                                                                        color: "white"
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    {/* <div className="mt-5 text-muted text-center">
                                        Don't have an account? <a href="auth-register.html">Create One</a>
                                    </div> */}
                                </div>
                            </div>
                        </section>
                    </div>
                    <AdminFooter />
                </div>
            </div>


            {/* Second modal for Add SUBADMIN */}



            <Modal className="btn-close"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show2}
                onHide={handleClose2}>
                <Modal.Header >
                    <Modal.Title>Invoice</Modal.Title>
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>
<div id="elem">
                    <body _c_t_common="1" data-new-gr-c-s-check-loaded="14.1043.0" data-gr-ext-installed="" cz-shortcut-listen="true" _c_t_j1="1">

                        <p style={{ display: 'inline', marginLeft: '15%' }}>Daraz Purchase Summary</p><p style={{ display: 'inline' }}>-<b>{Getallinvoicedata.OrderNo}</b></p><p style={{ display: 'inline', textAlign: 'center', margin: '0 auto', marginLeft: '15%' }}>{Getallinvoicedata.Created}</p>
                        {/* <button id="print" type="button" style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '6px', paddingBottom: '6px', fontSize: '18px', marginLeft: '20%', marginBottom: '10px' }} onclick="myfunc()">Print</button> */}
                        <table style={{ width: '100%', margin: '0 auto' }}>
                            <tbody style={{ outline: '1px solid black', padding: '5px' }}>
                                <tr>
                                    <td rowSpan={2} style={{ width: '40px', border: '1px solid black', textAlign: 'center' }}>
                                        <img style={{ width: '60%', maxWidth: '60%', minHeight: '60%', height: '60%' }} src="./invoice_files/metallic-logo-mockup-blue-wall_1389-1178.jpg" alt="" /></td>
                                    <td style={{ width: '40px', backgroundColor: 'aqua' }} />
                                    <td style={{ width: '40px', backgroundColor: 'pink', paddingRight: '5px' }} />
                                    <td style={{ width: '40px', backgroundColor: 'royalblue', paddingTop: '10px' }} />
                                    <td style={{ width: '40px', backgroundColor: 'pink', paddingBottom: '10px' }} />
                                </tr>
                                <tr>
                                    <td rowSpan={1} colSpan={4} style={{ borderBottom: '1px solid black', textAlign: 'center' }}><b>SELLER</b>CENTER</td>
                                </tr>
                                <tr style={{ border: '1px solid black' }}>
                                    <td style={{ border: '1px solid black' }}>{Getallinvoicedata.OrderNo}
                                    </td>
                                    <td style={{ bottom: '1px solid black' }}>{Getallinvoicedata.PrdouctName}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{ width: '100%', margin: '0 auto', marginTop: '10px' }}>
                            <tbody style={{ outline: '1px solid black', padding: '5px' }}>
                                <tr style={{ border: '1px solid black', outline: '1px solid black' }}>
                                    <td style={{ width: '40px', border: '1px solid black' }}>Purchase Summery Number:</td>
                                    <td style={{ border: '1px solid black' }} colSpan={2}>02</td>
                                    <td style={{ width: '50px', border: '1px solid black' }}>Payment Method:</td>
                                    <td style={{ width: '50px', border: '1px solid black' }}>{Getallinvoicedata.PaymentMethod}</td>
                                </tr>
                                <tr style={{ border: '1px solid black', outline: '1px solid black' }}>
                                    <td style={{ width: '40px', border: '1px solid black' }}>Purchase Summery Date:</td>
                                    <td style={{ border: '1px solid black' }} colSpan={2}>{Getallinvoicedata.Created}</td>
                                    <td style={{ width: '50px', border: '1px solid black' }}>CNIC:</td>
                                    <td style={{ width: '50px', border: '1px solid black' }} />
                                </tr>
                                <tr style={{ border: '1px solid black' }}>
                                    <td style={{ width: '40px', border: '1px solid black' }}>BILL TO:</td>
                                    <td style={{ border: '1px solid black' }} colSpan={2}>{Getallinvoicedata.Billto}</td>
                                    <td style={{ width: '50px', border: '1px solid black' }}>Delivered TO:</td>
                                    <td style={{ width: '50px', border: '1px solid black' }}>{Getallinvoicedata.Deliverdto}</td>
                                </tr>
                                <tr style={{ border: '1px solid black' }}>
                                    <td style={{ padding: '10px', border: '1px solid black' }}>Address:</td>
                                    <td style={{ border: '1px solid black' }} colSpan={2}>{Getallinvoicedata.Address}</td>
                                    <td style={{ width: '50px', border: '1px solid black' }}>Province</td>
                                    <td style={{ width: '50px', border: '1px solid black' }}>{Getallinvoicedata.Province}</td>
                                </tr>
                                <tr style={{ border: '1px solid black', outline: '1px solid black' }}>
                                    <td style={{ padding: '10px', border: '1px solid black' }}>PHONE:</td>
                                    <td style={{ border: '1px solid black' }} colSpan={2}>{Getallinvoicedata.CustomerNumber}</td>
                                    <td style={{ width: '50px', border: '1px solid black' }}>PHONE:</td>
                                    <td style={{ width: '50px', border: '1px solid black' }}>{Getallinvoicedata.CustomerNumber}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{ width: '100%', margin: '0 auto', marginTop: '20px' }}>
                            <tbody style={{ outline: '1px solid black', padding: '5px' }}>
                                <tr style={{ border: '1px solid black', outline: '1px solid black' }}>
                                    <th style={{ border: '1px solid black' }} colSpan={9}>Your Order Item:</th>
                                </tr>
                                <tr style={{ border: '1px solid black' }}>
                                    <th style={{ border: '1px solid black' }}>#</th>
                                    <th style={{ border: '1px solid black' }}>Product Name</th>
                                    <th style={{ border: '1px solid black' }}>Shop SKU</th>
                                    <th style={{ border: '1px solid black' }}>Seller SKU</th>
                                    <th style={{ border: '1px solid black', width: '160px' }}>Size</th>
                                    <th style={{ border: '1px solid black' }}>Paid Price</th>
                                    <th style={{ border: '1px solid black' }}>Price</th>
                                    <th style={{ border: '1px solid black' }}>Item Total</th>
                                </tr>
                                <tr style={{ border: '1px solid black', outline: '1px solid black' }}>
                                    <td style={{ border: '1px solid black' }}>1</td>
                                    <td style={{ border: '1px solid black' }}>{Getallinvoicedata.PrdouctName}</td>
                                    <td style={{ border: '1px solid black' }}>212954826_PK-1421272348</td>
                                    <td style={{ border: '1px solid black' }}>{Getallinvoicedata.PrdouctName}</td>
                                    <td style={{ border: '1px solid black', width: '160px' }}>Color<br />Family-Multicolor</td>
                                    <td style={{ border: '1px solid black' }}>59.00</td>
                                    <td style={{ border: '1px solid black' }}>59.00</td>
                                    <td style={{ border: '1px solid black' }}>{Getallinvoicedata.Total}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{ width: '55%', marginTop: '20px', textAlign: 'left', marginLeft: '45%' }}>
                            <tbody style={{ border: '1px solid black' }}>
                                <tr style={{ border: '1px solid black' }}>
                                    <td style={{ border: '1px solid black' }}>Subtotal:</td>
                                    <td style={{ border: '1px solid black' }}>71.00</td>
                                </tr>
                                <tr style={{ border: '1px solid black' }}>
                                    <td style={{ border: '1px solid black' }}>Shipping Cost:</td>
                                    <td style={{ border: '1px solid black' }}>12.00</td>
                                </tr>
                                <tr style={{ border: '1px solid black' }}>
                                    <td style={{ border: '1px solid black' }}>voucher:</td>
                                    <td style={{ border: '1px solid black' }}>0</td>
                                </tr>
                                <tr style={{ border: '1px solid black' }}>
                                    <th style={{ border: '1px solid black', alignItems: 'start' }}>Total</th>
                                    <th style={{ border: '1px solid black' }}>{Getallinvoicedata.Total}</th>
                                </tr>
                            </tbody>
                        </table>


                        <div id="pt-ext-root"></div>
                        </body>
                        </div>




                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => myfunc()} >
                        Print
                    </Button>
                    <Button variant="primary" onClick={()=>handleClose2()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}