import React, { useState, useEffect, useRef } from "react"
import Footer from '../../../components/footer/footer'
import { useHistory } from "react-router"
import AdminHeader from "../components/adminheader/AdminHeader"

// import TextEditor from '../addProduct/textEditor'
// import '../newAddproduct/newAddproduct.css'
import JoditEditor from "jodit-react";
import Swal from 'sweetalert2';
import BaseUrl from "../../../../../src/config/BaseUrl"
import image_url from "../../../../../src/config/BaseUrl"
import {
  BrowserRouter as Router,
  Switch,
  useLocation
} from "react-router-dom";
import { getApi, getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";

export default function NewProDetails() {



  useEffect(() => {

    // console.log("use effect ")
    ViewAllProDetails(location.state.productid)
  }, []);






  const history = useHistory();
  const [Step1, setStep1] = useState(true)
  const [Step2, setStep2] = useState(false)
  const [Step3, setStep3] = useState(false)
  const [Step4, setStep4] = useState(false)
  const [Loader, setLoader] = useState(false)


  // ============== SERVICE & DELIVERY ==============


  const NextStep = (val) => {
    if (val == 1) {
      setStep1(false)
      setStep2(true)
      setStep3(false)
      setStep4(false)
    } else if (val == 2) {
      setStep1(false)
      setStep2(false)
      setStep3(true)
      setStep4(false)
    } else if (val == 3) {
      setStep1(false)
      setStep2(false)
      setStep3(false)
      setStep4(true)
    } else if (val == 4) {
      setStep1(false)
      setStep2(false)
      setStep3(false)
      setStep4(true)
    }
  }
  const PreviousStep = (val) => {
    if (val == 1) {
      setStep1(true);
      setStep2(false)
      setStep3(false)
      setStep4(false)
    } else if (val == 2) {
      setStep1(true)
      setStep2(false)
      setStep3(false)
      setStep4(false)
    } else if (val == 3) {
      setStep1(false)
      setStep2(true)
      setStep3(false)
      setStep4(false)
    } else if (val == 4) {
      setStep1(false)
      setStep2(false)
      setStep3(true)
      setStep4(false)
    }
  }
  const CurruntStep = (val) => {
    if (val == 1) {
      setStep1(true);
      setStep2(false)
      setStep3(false)
      setStep4(false)
    } else if (val == 2) {
      setStep1(false)
      setStep2(true)
      setStep3(false)
      setStep4(false)
    } else if (val == 3) {
      setStep1(false)
      setStep2(false)
      setStep3(true)
      setStep4(false)
    } else if (val == 4) {
      setStep1(false)
      setStep2(false)
      setStep3(false)
      setStep4(true)
    }
  }


  // ============== PRICE & STOCK ==============

  const [selectedFiles, setSelectedFiles] = useState([]);
  console.log("selectedFiles==",selectedFiles)

  const handleImageChange = (e) => {
      // console.log("Files===>", e.target.files)
      if (e.target.files) {
          const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));

          // console.log("filesArray===>", filesArray);

          setSelectedFiles((prevImages) => prevImages.concat(filesArray));
          Array.from(e.target.files).map(
              (file) => URL.revokeObjectURL(file) // avoid memory leak
          );
      }
  };


  const removeImage = (index) => {
    setSelectedFiles([
        ...selectedFiles.slice(0, index),
        ...selectedFiles.slice(index + 1)
    ]);
  }


  const renderPhotos = (source) => {

    // console.log('source: ', source);
    return source.map((photo, index) => {
        return (
            <span className="imgUp">
                <img style={{ border: "1px solid black" }} src={photo} width="150" height="150" alt="" key={photo} />
                <button type="button" onClick={() => removeImage(index)}><i style={{ fontSize: 18 }} class="fas fa-trash-alt"></i></button>
            </span>
        )
    });
};

  const [GetAllAproducts, SetGetAllAproducts] = useState([])
  console.log("GetAllAproducts", GetAllAproducts)

  const Token = localStorage.getItem('Token');




  const location = useLocation()
  console.log("datalocation===>", location.state.productid)


//   view specific data

  const ViewAllProDetails = (id) => {
    setLoader(true);

    getApiWithToken(`${BaseUrl.Admin_Url}/GetDetailsofProduct?id=${id}`, "", Token)
      .then(({ data }) => {
        setLoader(false);
        if (data.status) {
          console.log("dataallview", data)


          SetGetAllAproducts(data)


        } else {
          // $(".loader").hide();
          Swal.fire({
            title: "Opps",
            text: data.message,
            icon: "error"
          })
        }
      }).catch(function (e) {
        // $(".loader").hide();
        Swal.fire({
          title: "Opps",
          text: e.message,
          icon: "error"
        })
      })

  }

  if (GetAllAproducts.length == 0) return   <div className="loader" />


  return (
    <>
      {Loader ? <div className="loader" /> : null}
      <div id="app">
        <div class="main-wrapper main-wrapper-1">
          <AdminHeader />
          <div className="main-content">
            <section className="section">
              <div class="row">
                <div className="col-md-12">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h4>View Product Detail</h4>
                    </div>
                    <div className="card-body">
                      {Step1 ?
                        <>
                          {/* <h3>Personal Information</h3> */}
                          <form >
                            <div className="row">
                              <div className="col-md-12 mb-2 text-center">
                                <button onClick={() => CurruntStep(1)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Basic Information</button>
                                <button disabled onClick={() => CurruntStep(2)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Detail Description</button>
                                <button disabled onClick={() => CurruntStep(3)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Price & Stock</button>
                                <button disabled onClick={() => CurruntStep(4)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Service & Delivery</button>
                              </div>
                            </div>
                            <hr />
                            <fieldset>
                              <div className="row basicInfo px-5" >
                                <div className="col-md-12">
                                  <div className="form-group form-float">
                                    <div className="form-line">
                                      <h6 style={{ marginBottom: 10 }}>BASIC INFORMATION</h6>
                                      <label className="form-label"><b>Product Name</b><span style={{ color: "red" }}> *</span></label>
                                      <input value={GetAllAproducts.ProductDetails.Product_Name} className="form-control" for="items" placeholder="Nikon Coolpix A300 Digital Camera" type="text" />
                                      {/* Category Suggestions */}
                                      <div class="row" style={{ backgroundColor: "white", marginRight: 15, marginLeft: 15, overflowX: "auto" }}>




                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row basicInfo px-5">
                                <div className="col-md-12">
                                  <div className="form-group form-float">
                                    <div className="form-line">
                                      <label className="form-label"><b>Category</b><span style={{ color: "red" }}> *</span></label>
                                      <input value={GetAllAproducts.ProductDetails.Category} placeholder="" type="text" className="form-control" />

                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Product Attributes */}
                              <div>

                                <div>
                                  <div className="row basicInfo px-5">
                                    <div className="col-md-12">
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <label className="form-label"><b>Video URL</b></label>
                                          <input value={GetAllAproducts.ProductDetails.VideoUrl} placeholder="" type="text" className="form-control" />
                                          {/* <h6 style={{ marginTop: 30 }}>PRODUCT ATTRIBUTES</h6> */}

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <p style={{ fontWeight: 'normal', fontSize: 14, marginLeft: 50, marginTop: -20 }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus molestiae veritatis aliquid, laborum quasi atque exercitationem. Voluptate ullam adipisci voluptatibus sapiente.</p> */}
                                  <div class="row d-flex mx-4">
                                    <div className="col-md-4">
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <label className="form-label"><b>Brand</b><span style={{ color: "red" }}> *</span></label>
                                          <input value={GetAllAproducts.ProductDetails.Brand_id} placeholder="" type="text" className="form-control" />
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="col-md-4">
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <label className="form-label"><b>Cable Length</b></label>
                                           <MultilevelDropdown />
                                          <select className="form-control">
                                            <option>Select Length</option>
                                            <option>UPC</option>
                                            <option>ATP</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div> */}
                                  </div>
                                  {/* <div className="d-flex justify-content-end" style={{ marginRight: 50 }}>
                                    <button className="btn btn-outline-danger">Clear All</button>
                                  </div> */}
                                </div>

                              </div>
                            </fieldset>
                            {/* Next Button */}
                            <div className="row">
                              <div className="col-md-12 pt-3 text-center">
                                <hr />
                                {/* <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(1)}>Previous</button> */}
                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => CurruntStep(2)} type="button">Next</button>
                              </div>
                            </div>
                          </form>
                        </>
                        : null
                      }
                      {Step2 ?
                        <>
                          {/* <h3>Detailed Description</h3> */}
                          <form  >
                            <div className="row">
                              <div className="col-md-12 mb-2 text-center">
                                <button onClick={() => CurruntStep(1)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Basic Information</button>
                                <button onClick={() => CurruntStep(2)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Detail Description</button>
                                <button disabled onClick={() => CurruntStep(3)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Price & Stock</button>
                                <button disabled onClick={() => CurruntStep(4)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Service & Delivery</button>
                              </div>
                            </div>
                            <fieldset>
                              <div className="row">
                                <div className="col-md-12 d-flex justify-content-center">
                                  <div className="form-group form-float">
                                    <div className="form-line">
                                      <label className="form-label"><b>Short Description</b><span style={{ color: "red" }}> *</span></label>
                                      <JoditEditor
                                        value={GetAllAproducts.ProductDetails.Short_Description}

                                      // value={content}

                                      // onBlur={handleUpdate}
                                      //



                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-center">
                                  <div className="form-group form-float">
                                    <div className="form-line">
                                      <label className="form-label"><b>Long Description</b></label>
                                      <JoditEditor


                                        value={GetAllAproducts.ProductDetails.long_Description}

                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-center">
                                  <div className="form-group form-float">
                                    <div className="form-line">
                                      <label className="form-label"><b>English Description</b><span style={{ color: "red" }}> *</span></label>
                                      <JoditEditor

                                        value={GetAllAproducts.ProductDetails.English_Description}


                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 px-5">
                                  <div className="form-group form-float">
                                    <div className="form-line">
                                      <label className="form-label"><b>What's in the box</b></label>
                                      <input value={GetAllAproducts.ProductDetails.Whats_in_the_Box} placeholder="" type="text" className="form-control" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <div className="row">
                              <div className="col-md-12 pt-3 text-center">
                                <hr />
                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(2)}>Previous</button>
                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => NextStep(2)}>Next</button>
                              </div>
                            </div>
                          </form>
                        </>
                        : null}
                      {Step3 ?
                        <>
                          {/* <h3>Price & Stock</h3> */}
                          <form  >
                            <div className="row">
                              <div className="col-md-12 mb-2 text-center">
                                <button onClick={() => CurruntStep(1)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Basic Information</button>
                                <button onClick={() => CurruntStep(2)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Detail Description</button>
                                <button onClick={() => CurruntStep(3)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Price & Stock</button>
                                <button disabled onClick={() => CurruntStep(4)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Service & Delivery</button>
                              </div>
                            </div>
                            <fieldset>
                              <div className="form-group form-float">
                                <div className="form-line" >
                                  <div style={{ border: "1px solid gray", borderRadius: 8, padding: 20, marginBottom: 10 }}>
                                    <h6 style={{ color: "black" }}>UPLOAD YOUR PRODUCT IMAGES</h6>
                                    <div style={{ marginTop: -5, marginBottom: 10 }}>Drop images here or click to upload</div>
                                    <hr />
                                    <div class="row">


                                      <div>

                                        <div className="result">
                                        {GetAllAproducts.ImageData?.map((e)=>
                                          <img style={{ maxWidth: "130px", borderRadius: "20px" }} src={(e.image)}></img>
                                          )}




                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                  <div className="" style={{ border: "1px solid gray", borderRadius: 8, padding: 20, marginBottom: 10 }} role="alert">
                                    <h6 style={{ color: "black" }}>PRICING & STOCK</h6>
                                    <hr />
                                    <div className="row text-dark" style={{ fontSize: 14 }}>
                                      {/* <div className="col-3"><b>AVALIBILITY</b></div> */}
                                      {/* <div className="col-3">PRICE<span style={{ color: "red" }}> *</span></div>
                                                                            <div className="col-3">SPECIAL PRICE</div> */}
                                      <div className="col-3"><b>QUANTITY</b></div>
                                    </div>
                                    <div className="row text-dark">
                                    <div className="col-md-3">
                                        <div className="form-group form-float">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <input value={GetAllAproducts.ProductDetails.Quantity} placeholder="" type="number" className="form-control" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        <div class="form-check" style={{ padding: 10 }} >
                                          {/* <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" /> */}
                                          {/* <label class="form-check-label" for="defaultCheck1"><b>Check your Product Availibility</b></label> */}
                                        </div>
                                      </div>
                                      {/* <div className="col-md-3">
                                        <div className="form-group form-float">
                                          <div className="form-group form-float">
                                            <div className="form-line">
                                              <input value={GetAllAproducts.ProductDetails.Quantity} placeholder="" type="number" className="form-control" />
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                      <div class="row text-dark px-4">
                                        <div className="row text-dark" style={{ fontSize: 14 }}>
                                          <div className="col-3"><b>PRICE</b><span style={{ color: "red" }}> *</span></div>
                                          <div className="col-2"><b>SPECIAL PRICE</b></div>
                                        </div>
                                        <div class="row text-dark">
                                          <div className="col-3">

                                            <input value={GetAllAproducts.ProductDetails.Price} placeholder="" type="number" className="form-control" />

                                          </div>
                                          <div className="col-2">

                                            <input value={GetAllAproducts.ProductDetails.Special_Price} type="number" className="form-control" />
                                          </div>

                                          <div>
                                            <div style={{ marginTop: -20 }}>
                                              <div className="col"><b>PROMOTION DATE</b></div>
                                              <div class="row text-dark">
                                                <div className="col">

                                                  <input value={GetAllAproducts.ProductDetails.Promotion_Start_Date} placeholder="Start Date" type="date" className="form-control" />
                                                </div>
                                                <div className="col">

                                                  <input value={GetAllAproducts.ProductDetails.Promotion_End_Date} placeholder="End Date" type="date" className="form-control" />
                                                </div>
                                              </div>
                                            </div>
                                            <div style={{ marginTop: 5 }}>
                                              <div className="col"><b>PROMOTION TIME</b></div>
                                              <div class="row text-dark">
                                                <div className="col">

                                                  <input value={GetAllAproducts.ProductDetails.Promotion_Start_Time} placeholder="Start Time" type="time" className="form-control" />
                                                </div>
                                                <div className="col">

                                                  <input value={GetAllAproducts.ProductDetails.Promotion_End_Time} placeholder=" Time End " type="time" className="form-control" />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <div className="row">
                              <div className="col-md-12 pt-3 text-center">
                                <hr />
                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(3)}>Previous</button>
                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => CurruntStep(4)} type="button" >Next</button>
                              </div>
                            </div>
                          </form>
                        </>
                        : null
                      }
                      {Step4 ?
                        <>
                          {/* <h3>Service & Delivery</h3> */}
                          {/* <h3>Service & Delivery</h3> */}
                          <form  >
                            <div className="row">
                              <div className="col-md-12 mb-2 text-center">
                                <button onClick={() => CurruntStep(1)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Basic Information</button>
                                <button onClick={() => CurruntStep(2)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Detail Description</button>
                                <button onClick={() => CurruntStep(3)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Price & Stock</button>
                                <button onClick={() => CurruntStep(4)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Service & Delivery</button>
                              </div>
                            </div>
                            <fieldset>
                              <div className="form-group form-float">
                                <div className="form-line mt-2">
                                  {/* <h5>Service</h5>
                                  <hr />
                                  <div class="row">
                                    <div className="col-md-4">
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <label className="form-label"><b>Warranty Type</b><span style={{ color: "red" }}> *</span></label>
                                          <select className="form-control">
                                            <option value={GetAllAproducts.ProductDetails.Warranty} >Choose Type</option>
                                            <option  >Warranty</option>
                                            <option >no Warranty</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <label className="form-label"><b>Warranty Period</b></label>
                                          <select className="form-control">
                                            <option>Select Period</option>
                                             <option></option>
                                            <option value={GetAllAproducts.ProductDetails.Warranty_Period}>1 Month</option>
                                            <option value={GetAllAproducts.ProductDetails.Warranty_Period}>6 Month</option>
                                            <option value={GetAllAproducts.ProductDetails.Warranty_Period}>1 year</option>

                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-10">
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <label className="form-label"><b>Warranty Policy</b></label>
                                          <input placeholder="" type="text" className="form-control" />
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                  <h5>Delivery</h5>
                                  <hr />
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="form-group form-float">
                                        <div className="form-line">
                                          <label className="form-label"><b>Package Weight(Kg)</b><span style={{ color: "red" }}> *</span></label>
                                          <input value={GetAllAproducts.ProductDetails.Package_Weight} placeholder="" type="number" className="form-control" />

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row" style={{ paddingLeft: 20 }}>
                                    <div className="form-group form-float">
                                      <div className="form-line">
                                        <label className="form-label"><b>Package Dimensions(cm)</b><span style={{ color: "red" }}> *</span></label>
                                        <div class="row">
                                          <div class="col-md-4">
                                            <input value={GetAllAproducts.ProductDetails.Lenght} placeholder="Length(cm)" type="number" className="form-control" />

                                          </div>
                                          <div class="col-md-4">
                                            <input value={GetAllAproducts.ProductDetails.Width} placeholder="Width(cm)" type="number" className="form-control" />

                                          </div>
                                          <div class="col-md-4">
                                            <input value={GetAllAproducts.ProductDetails.Height} placeholder="Height(cm)" type="number" className="form-control" />

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <div className="row">
                              <div className="col-md-12 pt-3 text-center">
                                <hr />
                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(4)}>Previous</button>
                                {/* <button  className="btn btn-primary col-md-3 mx-2" type="button" >Finish</button> */}
                              </div>
                            </div>
                          </form>
                        </>
                        : null
                      }
                    </div>
                  </div>
                  {/* <div className="mt-5 text-muted text-center">
                    Don't have an account? <a href="auth-register.html">Create One</a>
                  </div> */}
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}