import React, { useState, useEffect } from "react"
import { useHistory } from "react-router"
import BaseUrl from "../../../../config/BaseUrl"
import { getApiWithToken,PostApiWithOutToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import Swal from 'sweetalert2'
import background from "../../Admin/Adminlogin/background.png"


export default function SellerVerify(props) {
    const history = useHistory();
    const [Loader, setLoader] = useState(false)
    const [Email, SetEmail] = useState("")
    const [Password, SetPassword] = useState("")

    // CONFIRMATION CODE VALIDATION
    const [confirmationCode, setConfirmationcode] = useState("");
    const [confirmationCodeErr, setConfirmationcodeErr] = useState({});
    const [Ctrue, setCtrue] = useState(false);
    const [codeBtn, setcodeBtn] = useState(false);

    console.log("codebtn",codeBtn)
    // const [Email, SetEmail] = useState("")

    console.log("props",props.location.state.code)
    console.log("props",props.location.Email)


    const Token = localStorage.getItem('SellerToken');
    useEffect(() => {

            alert("your emaiverificationcode is" + " " + props.location.state.code)


    },[] )





  ////verify code api

  const VerifingCode = () => {

    setLoader(true);
    var formdata = new FormData();

    formdata.append("Email",props.location.Email );
    formdata.append("Code", confirmationCode);


    PostApi(`${BaseUrl.base_url}/VerifyCode`, formdata, Token)
        .then(({ data }) => {
            setLoader(false);
            if (data.status) {
                console.log("if work")


                history.push({
                    pathname: "/ConfirmPassword",
                         state: props.location.Email
                    })
            }
            else if(data.message == "Code is expire"){

                setcodeBtn(true)

                Swal.fire({
                    title: "Opps",
                    text: data.message,
                    icon: "error"
                })

            }

            else {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: data.message,
                    icon: "error"
                })
            }




        }).catch(function (e) {
            // $(".loader").hide();
            Swal.fire({
                title: "Opps",
                text: e.message,
                icon: "error"
            })
        })



}

    const formValidationConf = () => {
        const confirmationCodeErr = {};

        let isValid = true;

        if (confirmationCode.trim().length === 0) {
            confirmationCodeErr.confirmationCodenull = "Enter Confirmation Code";
            isValid = false;
            setConfirmationcodeErr(confirmationCodeErr);
            setCtrue(false);
            return isValid;
        }



        else if (confirmationCode.length < 4) {
            // console.log("lrnth")

            confirmationCodeErr.confirmationCodenull = "Please enter maximum 4 digits otp code";
            isValid = false;

            setConfirmationcodeErr(confirmationCodeErr);
            setCtrue(false);
            return isValid;

        }

        else if (confirmationCode.length > 4) {


            confirmationCodeErr.confirmationCodenull = "Please enter maximum 4 digits otp code";
            isValid = false;

            setConfirmationcodeErr(confirmationCodeErr);
            setCtrue(false);
            return isValid;

        }

        // else if (confirmationCode == props.location.state.code) {
        //     confirmationCodeErr.confirmationCodenull = "success";
        //     history.push({
        //         pathname: "/ConfirmPassword",
        //         state: props.location.Email
        //     })

        //     isValid = true;
        //     setConfirmationcodeErr(confirmationCodeErr);
        //     setCtrue(true);
        //     return isValid;





        // }


        // else {

        //     confirmationCodeErr.confirmationCodenull = "Please enter correct OTP number";
        //     isValid = false;
        //     setConfirmationcodeErr(confirmationCodeErr);
        //     setCtrue(false);
        //     return isValid;
        // }


        else{
            VerifingCode()
        }


    }



    const SendVerificationCode = () => {

        setcodeBtn(false)

        setLoader(true);
        var formdata = new FormData();
        formdata.append("Email", props.location.Email);

        PostApi(`${BaseUrl.base_url}/SendVerificationCode`, formdata)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("verification code email==>",data.code)
                    alert("your new emaiverificationcode is" + " " + data.code)

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })



    }














    return (
        <>
            {Loader?<div className="loader" />:null}
            <div id="app" style={{backgroundImage: `url(${background})`, marginTop: '-50px', backgroundSize: 'cover', height: '100vh' }}>
                <section className="section">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img style={{ width: "25%" }} src="assets/logo/HUGO-07.png"></img>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4>Verify Code</h4>
                                    </div>
                                    <div className="card-body">
                                        <form method="" action="#" className="needs-validation" noValidate>
                                            <div className="form-group">
                                                <label htmlFor="email">OTP</label>
                                                <input  value={confirmationCode}  onChange={(e) => setConfirmationcode(e.target.value)}  id="email" type="number" min="0" max="111111111111111111111"  className="form-control" name="email" tabIndex={1} required autofocus />
                                                {Ctrue ?
                                                                            <div>
                                                                                {Object.keys(confirmationCodeErr).map((key) => {
                                                                                    return <div style={{ color: "green" }}>{confirmationCodeErr[key]}</div>
                                                                                })}
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                {Object.keys(confirmationCodeErr).map((key) => {
                                                                                    return <div style={{ color: "red" }}>{confirmationCodeErr[key]}</div>
                                                                                })}
                                                                            </div>
                                                                        }
                                            </div>


                                            {/* <div className="form-group">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" name="remember" className="custom-control-input" tabIndex={3} id="remember-me" />
                                                    <label className="custom-control-label" htmlFor="remember-me">Remember Me</label>
                                                </div>
                                            </div> */}

                                            <div className="form-group">
                                                <button onClick={()=>formValidationConf()}  type="button" className="btn btn-primary btn-lg btn-block" tabIndex={4}>

                                                    Verify</button>

                                               {codeBtn ? <a className="customPointer" onClick={()=>SendVerificationCode()} style={{marginLeft:"200px"}}>Resend Code</a>: ""}

                                            </div>
                                        </form>



                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}